import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import DescriptionIcon from '@material-ui/icons/Description';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HomeIcon from '@material-ui/icons/Home';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

export default function NavMenu() {

  return (
      <List className="nav-menu">

        <ListItem button key="Home" component="a" href="#">
            <ListItemIcon><HomeIcon /></ListItemIcon>
        </ListItem>

        <ListItem button key="About" component="a" href="#about">
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary="About" />
        </ListItem>

        <ListItem button key="Portfolio" component="a" href="#portfolio">
          <ListItemIcon><FolderSpecialIcon /></ListItemIcon>
          <ListItemText primary="Portfolio" />
        </ListItem>

        <ListItem button key="Resume" component="a" href="#resume">
          <ListItemIcon><DescriptionIcon /></ListItemIcon>
          <ListItemText primary="Resume" />
        </ListItem>

        <ListItem button key="Contact" component="a" href="#contact">
          <ListItemIcon><ContactMailIcon /></ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>

        <ListItem 
          button 
          key="LinkedIn" 
          component="a" 
          href="https://www.linkedin.com/in/ronholt/" 
          target="_blank" 
          rel="noopener"
        >
          <ListItemIcon><LinkedInIcon /></ListItemIcon>
        </ListItem>

        <ListItem 
          button 
          key="GitHub" 
          component="a" 
          href="https://github.com/GitarMan" 
          target="_blank" 
          rel="noopener"
        >
          <ListItemIcon><GitHubIcon /></ListItemIcon>
        </ListItem>

      </List>
  );
}

