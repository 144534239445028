const skills = [
  {
    skill: 'WordPress', 
    proficiency: 100,
    details: 'Most of my professional experience involves WordPress. It has been easy to use for clients and has a huge developer community. My workflow at Awesurance generally involved building a custom theme for the client site and then importing / adding content through the CMS. I developed several starter-theme temeplates and custom plugins for internal use. I have also worked with many third-party plugins, such as GravityForms, WooCommerce, SiteOrigin Pagebuilder, Advanced Custom Fields, LearnDash, BuddyPress, & BBPress to name a few.',
    cols: '4',
  },
  {
    skill: 'HTML', 
    proficiency: 100,
    details: 'Semantic markup FTW!',
    cols: '1',
  },
  {
    skill: 'CSS/SCSS', 
    proficiency: 100,
    details: 'SCSS is awesome, but CSS3 features are catching up',
    cols: '1',
  },
  {
    skill: 'JavaScript', 
    proficiency: 80,
    details: '...is eating the world',
    cols: '1',
  },
  {
    skill: 'PHP', 
    proficiency: 70,
    details: 'I have lots of experience writing PHP for WordPress themes and plugins',
    cols: '1',
  },
  {
    skill: 'React', 
    proficiency: 60,
    details: 'I am new to React but loving it! This site is built with React and Material-UI',
    cols: '1',
  },
  {
    skill: 'Node.js', 
    proficiency: 50,
    details: 'I have some experience with Node.js.',
    cols: '1',
  },
  {
    skill: 'Express.js', 
    proficiency: 50,
    details: 'I have some experience with Express.js. The contact form on this site is handled by an Express.js server I built and am running on a VPS. You can check out the code on my GitHub.',
    cols: '1',
  },
  {
    skill: 'Linux', 
    proficiency: 80,
    details: 'Switched over to GNU/Linux (Pop!_OS) as my daily driver at the beginning of 2019 and never looked back! Most of my experience is on the LAMP (Linux, Apache, MySQL, & PHP) stack.',
    cols: '2',
  },
  {
    skill: 'Python',
    proficiency: 20,
    details: 'I have written a few small Python scripts, mostly to perform a specific function or use a specific Python library.',
  },
  {
    skill: 'Apache', 
    proficiency: 30,
    details: 'I know the basics. I use Apache for my local development server',
    cols: '1',
  },
  {
    skill: 'MySQL', 
    proficiency: 40,
    details: "SELECT * FROM Jobs WHERE CoworkerRating='Awesome';",
    cols: '2',
  },
  {
    skill: 'Photoshop', 
    proficiency: 50,
    subtitle: 'or GIMP / ImageMagick',
    details: 'I know my way around basic raster image editing. I also love the ImageMagick command line interface for writing shell scripts.',
    cols: '2',
  },
  {
    skill: 'Illustrator', 
    proficiency: 50,
    subtitle: 'or Inkscape',
    details: 'Similar to Photoshop / GIMP for raster graphics, I know the basics of editing vector graphics with Illustrator / Inkscape.',
    cols: '2',
  },
  {
    skill: 'Web Scraping', 
    proficiency: 50,
    subtitle: 'WebScraper.io, Scrapy, BeautifulSoup',
    details: 'When migrating a site with a lot of pages / articles, sometimes scraping is the answer. I have used Scrapy & WebScraper.io with WP All Import Pro to make this easier.',
    cols: '2',
  },
  {
    skill: 'Hosting', 
    proficiency: 70,
    details: 'Most of my sites are hosted on WP Engine where we get collectively ~5 million uniques/year. Learning more about administering my own Virtual Private Servers for future projects.',
    cols: '2',
  },
  {
    skill: 'Email', 
    proficiency: 80,
    subtitle: 'IT / Marketing / Transactional',
    details: 'I have set up... email accounts, DNS settings, & SPF/DKIM/DMARC policies, Marketing Campaigns with MailChimp / Constant Contact, and programmed Transactional Emails using APIs such as AWS SES / Mandrill / Mailgun / SendGrid.',
    cols: '3',
  },
  {
    skill: 'Vim', 
    proficiency: 80,
    details: "Not only can I exit Vim ( :wq ), but Vim is my preferred text editor / IDE! It takes some getting used to, but once you do you'll never want to go back.",
    cols: '1',
  },
  {
    skill: 'Dvorak', 
    proficiency: 100,
    details: 'I type Dvorak. Weird, I know. But my fingers probably travel less distance than yours while typing.',
    cols: '1',
  },
];

export default skills;
