import React from 'react';
import resumeData from './resumeData';
import { Link, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Resume.scss';
import bridge from './img/golden_gate_grey.svg';
import ExploreIcon from '@material-ui/icons/Explore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import WorkIcon from '@material-ui/icons/Work';
import CodeIcon from '@material-ui/icons/Code';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import BuildIcon from '@material-ui/icons/Build';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(theme => ({
  resume: {
    padding: '1rem 1rem 5rem',
    marginBottom: '10em',
    '& > *': {
      padding: '1rem',
    },
  },
  resume__name: {
    backgroundColor: '#CCC',
    padding: '1rem',
  },
  resume__title: {
    padding: '1rem',
  },
  resume__sectiontitle: {
    backgroundColor: '#CCC',
    padding: '1rem',
    marginBottom: '1rem',
  },
  resume__workplace: {
    paddingBottom: '1rem',
    borderBottom: '1px solid #CCC',
  },
  locationGridItem: {
    textAlign: 'center',
  },
  bridge: {
    width: '50%',
  },
  resume__school: {
    
  }
}));

export default function Resume() {
  const classes = useStyles();

  return (
    <Paper className={classes.resume}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.resume__name} variant="h2" align="center" component="h3">{resumeData.basics.name}</Typography>
          <Typography className={classes.resume__title} variant="h3" align="center" component="h3"><CodeIcon fontSize="large" /> {resumeData.basics.label}</Typography>
          <Typography variant="body1" align="center">{resumeData.basics.summary}</Typography>
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <dl>
            <dt><Typography className={classes.resume__sectiontitle} variant="h4" align="center" component="h4"><FormatListBulletedIcon /> TL;DR</Typography></dt>
            { resumeData.basics.tldr.map( (bullet, i) => <dd key={'tldr'+i}><Typography variant="body1">{bullet}</Typography></dd> ) }
          </dl>
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <dl>
            <dt><Typography className={classes.resume__sectiontitle} variant="h4" align="center" component="h4"><BuildIcon /> Top Skills</Typography></dt>
            { resumeData.basics.topSkills.map( (bullet, i) => <dd key={'topskills-'+i}><Typography variant="body1">{bullet}</Typography></dd> ) }
          </dl>
        </Grid>
        <Grid item xs={12} lg={6} xl={3} className={classes.locationGridItem}>
          <Typography className={classes.resume__sectiontitle} variant="h4" align="center"><ExploreIcon /> Location</Typography>
          <Typography variant="h5" align="center">{ resumeData.basics.location.city }</Typography>
          <img src={bridge} className={classes.bridge} alt="Golden Gate Bridge" align="center" />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Typography className={classes.resume__sectiontitle} variant="h4" align="center"><AccountCircleIcon /> Social</Typography>
          { 
            resumeData.basics.profiles.map( (profile, i) => 
              <dl key={'profile-'+i}>
              <dt><Typography variant="h5">{profile.network}</Typography></dt>
              <dd><Link href={profile.url} rel="noopener" target="_blank" variant="body1">{profile.url}</Link></dd>
              </dl>
            ) 
          }
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.resume__sectiontitle} variant="h4" align="center"><WorkIcon /> Work Experience</Typography>
          {
            resumeData.work.map( (workplace, i) =>
              <dl key={'workplace-'+i} className={classes.resume__workplace}>
                <dt><Typography variant="h5">{workplace.name}</Typography></dt>
                <dd className="resume__dd--no-bullet"><Typography variant="body1">{workplace.description}</Typography></dd>
                <dd><Typography variant="body1"><strong>Location: </strong>{workplace.location}</Typography></dd>
                <dd><Typography variant="body1"><strong>Role: </strong>{workplace.position}</Typography></dd>
                
                { workplace.url ?
                  <dd><Typography variant="body1"><strong>Link: </strong>
                        <Link href={workplace.url} target="_blank" rel="noopener">{workplace.url}</Link>
                  </Typography></dd>
                  : ''
                }

                <dd><Typography variant="body1"><strong>From: </strong>{workplace.startDate} <strong>To:</strong> {workplace.endDate}</Typography></dd>
                {
                  workplace.summary.map( (paragraph, i) => 
                    <dd key={'summary-'+i} className="resume__dd--no-bullet">
                      <Typography variant="body1">{paragraph}</Typography> 
                    </dd>
                  )
                }
                {
                  workplace.highlights ?
                    <dd className="resume__dd--no-bullet"><dl>
                      <dt><Typography variant="h6">Highlights:</Typography></dt>
                      { workplace.highlights.map( (highlight, i) => 
                          <dd key={'highlight-'+i}><Typography variant="body1">{highlight}</Typography></dd> 
                      ) }
                    </dl></dd>
                  : '' 
                }
                {
                  workplace.technologies ?
                    <dd className="resume__dd--no-bullet"><dl>
                      <dt><Typography variant="h6">Technologies worked with at Awesurance Include:</Typography></dt>
                      { workplace.technologies.map( (tech, i) => 
                          <dd key={'technologies-'+i}><Typography variant="body1">{tech}</Typography></dd> 
                      ) }
                    </dl></dd>
                  : '' 
                }
              </dl>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.resume__sectiontitle} variant="h3" align="center"><ImportContactsIcon fontSize="large" /> Education</Typography>
        </Grid>
        {
          resumeData.education.map( (school, i) => 
            <Grid item xs={12} md={6} key={'school-'+i}>
              <dl>
              <dt className={classes.resume__sectiontitle}><Typography variant="h4" align="center">{school.institution}</Typography></dt>
              <dd><Typography variant="body1"><strong>Degree: </strong>{school.studyType}</Typography></dd>
              <dd><Typography variant="body1"><strong>Field of Study: </strong>{school.area}</Typography></dd>
              <dd><Typography variant="body1"><strong>From: </strong>{school.startDate} <strong>To:</strong> {school.endDate}</Typography></dd>
              </dl>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <Typography className={classes.resume__sectiontitle} variant="h3" align="center"><CloudDownloadIcon /> Download</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            <Link href="doc/Resume_Ron-Holt_06-29-2020.pdf" target="_blank" rel="noopener"><PictureAsPdfIcon fontSize="large" /> Ron Holt Resume</Link>
          </Typography>          
        </Grid>
      </Grid>
    </Paper>
  );
}
