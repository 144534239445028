import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import tileData from './tileData';
import PortfolioTile from './PortfolioTile';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10rem',
  },
}));


export default function PortfolioGrid() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {tileData.map( (tile, i) => (
        <PortfolioTile 
          tileData={tile}
          key={'tile-'+i} 
        />
      ))}
    </Grid>
  );
}

