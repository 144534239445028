import React from 'react';
import { Link, Typography, Toolbar, useScrollTrigger, AppBar, Paper, Avatar, Container } from '@material-ui/core';
import PortfolioGrid from './PortfolioGrid';
import 'normalize.css';
import './App.css';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MenuDrawer from './MenuDrawer';
import texturedPaper from './img/textured_paper.png';
import keyboardDesk from './img/k2-keyboard-desk.jpg';
import Nametag from './Nametag';
import ron_avatar from './img/ron_avatar.jpg';
import styled, { keyframes } from 'styled-components';
import { zoomInDown, rollIn } from 'react-animations';
import NavMenu from './NavMenu';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import SectionHeader from './SectionHeader';
import SkillsChart from './SkillsChart';
import ContactForm from './ContactForm';
import Resume from './Resume';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

AOS.init();

const RollIn = styled.div`animation: 1s ${keyframes`${rollIn}`}`;
const ZoomInDown = styled.div`animation: 1s ${keyframes`${zoomInDown}`}`;

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
    scrollBehavion: 'smooth',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    backgroundImage: 'url('+keyboardDesk+')',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 75%',
    width: '100%',
    height: '50vh',
    minHeight: '30rem',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  avatar: {
    height: '15rem',
    width: '15rem',
    marginBottom: '1rem',
  },
  headerNametag: {
    transform: 'rotate(-45deg)',
  },
  toolbarName: {
    fontSize: '2rem',
  },
  introText: {
    padding: '1rem',
    '& > *': {
      marginBottom: '1rem',
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '2rem',
    color: '#CCC',
    backgroundColor: '#333',
    textAlign: 'center',
    '& > *': {
      padding: '1rem',
    }
  }
})); 


function ElevationScroll(props) {
  const { children } = props;
  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


function App(props) {
  const classes = useStyles();

  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '"Raleway"',
        'Helvetica', 
        'Arial', 
        'sans-serif',
      ].join(','),
    },
  });


  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar variant="dense" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <NavMenu toolbar />
                <MenuDrawer />
                <Typography variant="h1" className={classes.toolbarName}>
                  <span style={{whiteSpace: 'nowrap'}}>Ron Holt</span>
                  <span id="webDevTitle">
                    &nbsp;&#8226;&nbsp; 
                    <span style={{whiteSpace: 'nowrap'}}>Web Developer</span>
                  </span>
                </Typography>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar variant="dense" /> 
        <main style={{backgroundImage: 'url('+texturedPaper+')'}}>
          <header className={classes.header} >
            <RollIn>
              <Avatar className={classes.avatar} src={ron_avatar} />
            </RollIn>
            <ZoomInDown>
              <Nametag name="Ron Holt" />
            </ZoomInDown>
          </header>
          <section id="about">
            <SectionHeader text="About" />
            <Container>
              <Paper className={classes.introText} data-aos="zoom-in">
                <Typography variant="h5" component="p">
                  Hi, my name is Ron! I'm a full-time web developer, general tech enthusiast, and I built this site to show off some of my work. I also have a background in business / entrepreneurship and I like hearing good ideas and connecting smart people together.
                </Typography>
                <Typography variant="h5" component="p">
                  If there is something you think I can help you with, or you just want to chat over coffee (or beer),<sup>*</sup> then don't hesitate to reach out! <LocalCafeIcon />
                </Typography>
                <Typography variant="subtitle1" align="right">
                  <sup>*</sup>When it is safe to reduce our social distance to normal levels, that is.<InsertEmoticonIcon />
                </Typography>
              </Paper>
              <Typography variant="h3" align="center" style={{marginTop: '5rem', padding: '1em'}}>Tech Skills:</Typography>
              <SkillsChart />
            </Container>
          </section>
          <section id="portfolio">
            <SectionHeader text="Portfolio" />
            <Container>
              <PortfolioGrid />
            </Container>
          </section>
          <section id="resume">
            <SectionHeader text="Resume" />
              <Container 
                maxWidth="lg"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <Resume />
              </Container>
          </section>
          <section id="contact">
            <SectionHeader text="Contact" />
            <Container 
              maxWidth="sm" 
              data-aos="zoom-in-up" 
              data-aos-duration="500"
            >
              <Paper>
                <ContactForm />
              </Paper>
            </Container>
          </section>
          <footer className={classes.footer}>
            <Typography variant="body1">This site is built with React & Material-UI by Me, of course</Typography>
            <Typography variant="body1">
              golden gate bridge by Simon Child from the <Link href="http://thenounproject.com/" target="_blank" rel="noopener">Noun Project</Link>
            </Typography>
          </footer>
        </main>
      </ThemeProvider>
    </div>
  );
}

export default App;
