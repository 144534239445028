const tileData = [
{
  "directory": "awesurance-com",
  "images": {
    "featuredThumbnail": "awesurance-com/awesurance-com_thumb.png",
    "thumbnails": [ 
      "awesurance-com/awesurance-com_thumb.png",
      "awesurance-com/awesurance-com-work_thumb.png",
      "awesurance-com/awesurance-com-about_thumb.png",
      "awesurance-com/awesurance-com-services_thumb.png",
      "awesurance-com/awesurance-com-portfolio_categories-video_thumb.png",
      "awesurance-com/awesurance-com-portfolio_categories-website_thumb.png",
      "awesurance-com/awesurance-com-portfolio_categories-print-page_thumb.png"
      ],
    "cropped": [
      "awesurance-com/awesurance-com_cropped-1920x1280.png",
      "awesurance-com/awesurance-com-work_cropped-1920x1280.png",
      "awesurance-com/awesurance-com-about_cropped-1920x1280.png",
      "awesurance-com/awesurance-com-services_cropped-1920x1280.png",
      "awesurance-com/awesurance-com-portfolio_categories-video_cropped-1920x1280.png",
      "awesurance-com/awesurance-com-portfolio_categories-website_cropped-1920x1280.png",
      "awesurance-com/awesurance-com-portfolio_categories-print-page_cropped-1920x1280.png"
      ],
    "featuredFull": "awesurance-com/awesurance-com.png",
    "full": [
      "awesurance-com/awesurance-com.png",
      "awesurance-com/awesurance-com-work.png",
      "awesurance-com/awesurance-com-about.png",
      "awesurance-com/awesurance-com-services.png",
      "awesurance-com/awesurance-com-portfolio_categories-video.png",
      "awesurance-com/awesurance-com-portfolio_categories-website.png",
      "awesurance-com/awesurance-com-portfolio_categories-print-page.png"
      ]
  },
  "url": "awesurance.com/",
  "showLink" : true,
  "title": "Awesurance", 
  "tags": ["Agency Site", "Portfolio", "Custom Theme", "WordPress"],
  "description": "This was the Awesurance main site designed to help showcase some of Awesurance's past work and generate leads for the agency. I made the custom WordPress theme based on the Underscores starter theme which includes a Custom Post Type for Portfolio Items. I contributed much of the content / copy as well. Generally with Awesurance projects, graphic design & logos were done by my partner Josh Nadler while implementation of styles & functionality was done by me. We would also sometimes utilize additional contractors for repetitive and non-technical tasks, such as generating landing pages or social media management.",
  "order": 11
},
{
  "directory": "therippleindy-com",
  "images": {
    "featuredThumbnail": "therippleindy-com/therippleindy-com_thumb.png",
    "thumbnails": [ 
      "therippleindy-com/therippleindy-com_thumb.png",
      "therippleindy-com/therippleindy-com-map_thumb.png",
      "therippleindy-com/therippleindy-com-photos_thumb.png",
      "therippleindy-com/therippleindy-com-amenities_thumb.png",
      "therippleindy-com/therippleindy-com-floorplans_thumb.png"
      ],
    "cropped": [
      "therippleindy-com/therippleindy-com_cropped-1920x1280.png",
      "therippleindy-com/therippleindy-com-map_cropped-1920x1280.png",
      "therippleindy-com/therippleindy-com-photos_cropped-1920x1280.png",
      "therippleindy-com/therippleindy-com-amenities_cropped-1920x1280.png",
      "therippleindy-com/therippleindy-com-floorplans_cropped-1920x1280.png"
      ],
    "featuredFull": "therippleindy-com/therippleindy-com.png",
    "full": [
      "therippleindy-com/therippleindy-com.png",
      "therippleindy-com/therippleindy-com-map.png",
      "therippleindy-com/therippleindy-com-photos.png",
      "therippleindy-com/therippleindy-com-amenities.png",
      "therippleindy-com/therippleindy-com-floorplans.png"
      ]
  },
  "url": "therippleindy.com/",
  "showLink" : true,
  "title": "The Ripple", 
  "tags": ["Apartment Site", "Property Management Site", "Custom Theme", "WordPress"],
  "description": "The Ripple is an apartment building in Indianapolis, IN, managed by Zinkan & Barker Development Co. This site is designed to showcase the building, the different floor plans, amenities, and location for prospective tenants. I set up the site & made the theme, incorporating existing brand guidelines with a focus on showcasing photos & floor plans.",
  "order": 15
},
{
  "directory": "podcast-mikestromsoe-com",
  "images": {
    "featuredThumbnail": "podcast-mikestromsoe-com/podcast-mikestromsoe-com_thumb.png",
    "thumbnails": [ 
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com_thumb.png",
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com-episodes_thumb.png",
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com-2020-02-13-episode-50-trusted-authority_thumb.png"
      ],
    "cropped": [
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com_cropped-1920x1280.png",
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com-episodes_cropped-1920x1280.png",
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com-2020-02-13-episode-50-trusted-authority_cropped-1920x1280.png"
      ],
    "featuredFull": "podcast-mikestromsoe-com/podcast-mikestromsoe-com.png",
    "full": [
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com.png",
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com-episodes.png",
      "podcast-mikestromsoe-com/podcast-mikestromsoe-com-2020-02-13-episode-50-trusted-authority.png"
      ]
  },
  "url": "podcast.mikestromsoe.com/",
  "showLink" : true,
  "title": "The Unstoppable Profit Podcast", 
  "tags": ["Podcast Site", "Embedded Audio Player", "Custom Theme", "WordPress"],
  "description": "This is a podcast site we built for Mike Stromsoe, a longtime Awesurance client, that has a fixed, embedded audio player, posts for each podcast, as well as subscription links. I made the theme, set up the site, and implemented the Blubrry PowerPress plugin as a fixed footer.",
  "order": 19
},
{
  "directory": "diamondtreeclub-com",
  "images": {
    "featuredThumbnail": "diamondtreeclub-com/diamondtreeclub-com_thumb.png",
    "thumbnails": [ 
      "diamondtreeclub-com/diamondtreeclub-com_thumb.png",
      "diamondtreeclub-com/diamondtreeclub-com-menus_thumb.png",
      "diamondtreeclub-com/diamondtreeclub-com-contact_thumb.png",
      "diamondtreeclub-com/diamondtreeclub-com-dispensaries_thumb.png",
      "diamondtreeclub-com/diamondtreeclub-com-online-order-east-bend_thumb.png"
      ],
    "cropped": [
      "diamondtreeclub-com/diamondtreeclub-com_cropped-1920x1280.png",
      "diamondtreeclub-com/diamondtreeclub-com-menus_cropped-1920x1280.png",
      "diamondtreeclub-com/diamondtreeclub-com-contact_cropped-1920x1280.png",
      "diamondtreeclub-com/diamondtreeclub-com-dispensaries_cropped-1920x1280.png",
      "diamondtreeclub-com/diamondtreeclub-com-online-order-east-bend_cropped-1920x1280.png"
      ],
    "featuredFull": "diamondtreeclub-com/diamondtreeclub-com.png",
    "full": [
      "diamondtreeclub-com/diamondtreeclub-com.png",
      "diamondtreeclub-com/diamondtreeclub-com-menus.png",
      "diamondtreeclub-com/diamondtreeclub-com-contact.png",
      "diamondtreeclub-com/diamondtreeclub-com-dispensaries.png",
      "diamondtreeclub-com/diamondtreeclub-com-online-order-east-bend.png"
      ]
  },
  "url": "diamondtreeclub.com/",
  "showLink" : true,
  "title": "DiamondTREE", 
  "tags": ["Cannabis Site", "Custom Theme", "WordPress"],
  "description": "DiamondTREE is a cannabis dispensary brand with three locations based in Bend, OR. This was the second site I built for them. I worked with Founder Sam Stapleton on both projects and for this site he had a specific design in PSD format that he wanted implemented into a WordPress site. I made the custom theme and incorporated the third-party Dutchie marketplace integration that allows customers to place orders for pickup online.",
  "order": 23
},
{
  "directory": "hyroot-joshnadler-com",
  "images": {
    "featuredThumbnail": "hyroot-joshnadler-com/hyroot-joshnadler-com_thumb.png",
    "thumbnails": [ 
      "hyroot-joshnadler-com/hyroot-joshnadler-com_thumb.png",
      "hyroot-joshnadler-com/hyroot-joshnadler-com-merch_thumb.png",
      "hyroot-joshnadler-com/hyroot-joshnadler-com-contact_thumb.png"
      ],
    "cropped": [
      "hyroot-joshnadler-com/hyroot-joshnadler-com_cropped-1920x1280.png",
      "hyroot-joshnadler-com/hyroot-joshnadler-com-merch_cropped-1920x1280.png",
      "hyroot-joshnadler-com/hyroot-joshnadler-com-contact_cropped-1920x1280.png"
      ],
    "featuredFull": "hyroot-joshnadler-com/hyroot-joshnadler-com.png",
    "full": [
      "hyroot-joshnadler-com/hyroot-joshnadler-com.png",
      "hyroot-joshnadler-com/hyroot-joshnadler-com-merch.png",
      "hyroot-joshnadler-com/hyroot-joshnadler-com-contact.png"
      ]
  },
  "url": "hyroot.joshnadler.com/",
  "showLink" : true,
  "title": "Hyroot Organics", 
  "tags": ["Cannabis Site", "Custom Theme", "WooCommerce", "WordPress"],
  "description": "Hyroot Organics is an upcoming cannabis dispensary based in La Pine, OR. This site was designed as a brochure for an upcoming brand, with WooCommerce integration for selling merchandise, and a placeholder for future integration with a third-party menu (such as Dutchie) as soon as the business launches. I made the theme for this site and populated the content.",
  "order": 20
},
{
  "directory": "insureflix-com",
  "images": {
    "featuredThumbnail": "insureflix-com/insureflix-com_thumb.png",
    "thumbnails": [ 
      "insureflix-com/insureflix-com_thumb.png",
      "insureflix-com/insureflix-com-faqs_thumb.png",
      "insureflix-com/insureflix-com-about_thumb.png"
      ],
    "cropped": [
      "insureflix-com/insureflix-com_cropped-1920x1280.png",
      "insureflix-com/insureflix-com-faqs_cropped-1920x1280.png",
      "insureflix-com/insureflix-com-about_cropped-1920x1280.png"
      ],
    "featuredFull": "insureflix-com/insureflix-com.png",
    "full": [
      "insureflix-com/insureflix-com.png",
      "insureflix-com/insureflix-com-faqs.png",
      "insureflix-com/insureflix-com-about.png"
      ]
  },
  "url": "insureflix.com/",
  "showLink" : true,
  "title": "InsureFlix", 
  "tags": ["Sales Site", "Custom Theme", "WooCommerce", "WordPress"],
  "description": "InsureFlix was a small division of Awesurance that worked with a third-party video creator to insert custom logos / calls-to-action into generic video ads that could be then embedded into our client's websites or social media. This was a sales site to showcase the videos & take orders using WooCommerce. I set up the site and made the theme.",
  "order": 11
},
{
  "directory": "insurevideos-com",
  "images": {
    "featuredThumbnail": "insurevideos-com/insurevideos-com-video_thumb.png",
    "thumbnails": [ 
      "insurevideos-com/insurevideos-com-video_thumb.png",
      "insurevideos-com/insurevideos-com-my-account_thumb.png",
      "insurevideos-com/insurevideos-com-video-idea_thumb.png",
      "insurevideos-com/insurevideos-com-example-videos_thumb.png"
      ],
    "cropped": [
      "insurevideos-com/insurevideos-com-video_cropped-1920x1280.png",
      "insurevideos-com/insurevideos-com-my-account_cropped-1920x1280.png",
      "insurevideos-com/insurevideos-com-video-idea_cropped-1920x1280.png",
      "insurevideos-com/insurevideos-com-example-videos_cropped-1920x1280.png"
      ],
    "featuredFull": "insurevideos-com/insurevideos-com.png",
    "full": [
      "insurevideos-com/insurevideos-com.png",
      "insurevideos-com/insurevideos-com-video.png",
      "insurevideos-com/insurevideos-com-my-account.png",
      "insurevideos-com/insurevideos-com-video-idea.png",
      "insurevideos-com/insurevideos-com-example-videos.png"
      ]
  },
  "url": "insurevideos.com/",
  "showLink" : true,
  "title": "InsureVideos", 
  "tags": ["Sales Site", "SAAS", "Custom Video Embeds", "Wistia API", "WooCommerce", "Subscription", "Custom Theme", "WordPress"],
  "description": "InsureVideos was a division of Awesurance. It was a subscription service that granted the subscriber access to a proprietary library of educational or sales videos on the subject of insurance that could be embedded into our clients websites. The videos were hosted on Wistia and then embeddable using their video API. I built the site / theme using custom post types for the videos. It used the WooCommerce Subscriptions extension. Once subscribed, the user could log on and browse a private library of InsureVideos videos. Once they found a video they liked, the user could then copy an embed code that was customizable using an HTML/JavaScript interface based on the Wistia API. I also did the research on video conversions and wrote the long-form sales letter on the homepage.",
  "order": 9
},
{
  "directory": "proposals-awesurance-com",
  "images": {
    "featuredThumbnail": "proposals-awesurance-com/proposals-awesurance-com-home-not-logged-in_thumb.png",
    "thumbnails": [ 
      "proposals-awesurance-com/proposals-awesurance-com-home-not-logged-in_thumb.png",
      "proposals-awesurance-com/proposals-awesurance-com-unravelpc-password-protected_thumb.png"
      ],
    "cropped": [
      "proposals-awesurance-com/proposals-awesurance-com-home-not-logged-in_cropped-1920x1280.png",
      "proposals-awesurance-com/proposals-awesurance-com-unravelpc-password-protected_cropped-1920x1280.png"
      ],
    "featuredFull": "proposals-awesurance-com/proposals-awesurance-com-unravelpc.png",
    "full": [
      "proposals-awesurance-com/proposals-awesurance-com-unravelpc.png",
      "proposals-awesurance-com/proposals-awesurance-com-2019-zinkanbarker.png",
      "proposals-awesurance-com/proposals-awesurance-com-home-not-logged-in.png",
      "proposals-awesurance-com/proposals-awesurance-com-unravelpc-password-protected.png"
      ]
  },
  "url": "proposals.awesurance.com/",
  "showLink" : false,
  "title": "Awesurance Proposals", 
  "tags": ["Sales Proposals Site", "Templated Proposal Generator", "Custom Theme", "WordPress"],
  "description": "The Awesurance Proposals site was developed first as an internal sales tool, and later sold to clients, as a way to streamline the process of making great-looking sales proposals that prospective clients could view online behind a simple password-protected page and then sign. In this first internal version, individual proposals were generated using SiteOrigin Page Builder Templates. In client versions, this method was revamped using Gravity Forms so that when the staff logged in, they could fill out a form (without going into the WordPress Admin) and submit it, and that would create the post and give them the permalink which could be easily copied and sent to the end user. I made everything on these sites except for logos.",
  "order": 17
},
{
  "directory": "proposals-goodwillfinancial-com",
  "images": {
    "featuredThumbnail": "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-employee-dashboard_thumb.png",
    "thumbnails": [ 
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-employee-dashboard_thumb.png",
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-create-a-new-proposal_thumb.png",
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-proposal-acme-roadrunner-hunters-co_thumb.png"
      ],
    "cropped": [
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-employee-dashboard_cropped-1920x1280.png",
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-create-a-new-proposal_cropped-1920x1280.png",
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-proposal-acme-roadrunner-hunters-co_cropped-1920x1280.png"
      ],
    "featuredFull": "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-employee-dashboard.png",
    "full": [
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-employee-dashboard.png",
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-create-a-new-proposal.png",
      "proposals-goodwillfinancial-com/proposals-goodwillfinancial-com-proposal-acme-roadrunner-hunters-co.png"
      ]
  },
  "url": "proposals.goodwillfinancial.com/",
  "showLink" : false,
  "title": "Goodwill Financial Proposals", 
  "tags": ["Sales Proposals Site", "Templated Proposal Generator", "Custom Theme", "WordPress"],
  "description": "The Awesurance Proposals site was developed first as an internal sales tool, and later sold to clients, as a way to streamline the process of making great-looking sales proposals that prospective clients could view online behind a simple password-protected page and then sign. In this client version, this method was revamped using Gravity Forms so that when the staff logged in, they could fill out a form (without going into the WordPress Admin) and submit it, and that would create the post and give them the permalink which could be easily copied and sent to the end user. I made everything on these sites except for logos.",
  "order": 19
},
{
  "directory": "thebutlerhub-com",
  "images": {
    "featuredThumbnail": "thebutlerhub-com/thebutlerhub-com_thumb.png",
    "thumbnails": [ 
      "thebutlerhub-com/thebutlerhub-com_thumb.png",
      "thebutlerhub-com/thebutlerhub-com-library_thumb.png",
      "thebutlerhub-com/thebutlerhub-com-tools-it-support-request_thumb.png",
      "thebutlerhub-com/thebutlerhub-com-library-category-hub-tutorials_thumb.png"
      ],
    "cropped": [
      "thebutlerhub-com/thebutlerhub-com_cropped-1920x1280.png",
      "thebutlerhub-com/thebutlerhub-com-library_cropped-1920x1280.png",
      "thebutlerhub-com/thebutlerhub-com-tools-it-support-request_cropped-1920x1280.png",
      "thebutlerhub-com/thebutlerhub-com-library-category-hub-tutorials_cropped-1920x1280.png"
      ],
    "featuredFull": "thebutlerhub-com/thebutlerhub-com.png",
    "full": [
      "thebutlerhub-com/thebutlerhub-com.png",
      "thebutlerhub-com/thebutlerhub-com-library.png",
      "thebutlerhub-com/thebutlerhub-com-tools-it-support-request.png",
      "thebutlerhub-com/thebutlerhub-com-library-category-hub-tutorials.png"
      ]
  },
  "url": "thebutlerhub.com/",
  "showLink" : false,
  "title": "The Butler Hub", 
  "tags": ["Intranet Site", "Library Plugin", "Water Cooler Social Plugin", "Google Search Plugin", "Suggestions Plugin", "Custom Theme", "WordPress"],
  "description": "The Butler Hub is a company intranet and an example of one of our Intranet Experts sites. Intranet Experts was a division of Awesurance that built WordPress-based company intranets as an internal tool to post news, collect documents, standard operating procedures, training tools, videos, social interactions, and generally aggregate company resources all in one place. Each Intranet typically has one or more version of our custom Library Plugin that I made which adds a custom post type with custom archives for easily browsing and navigating articles. (Sometimes called other things at the client's request such as 'Playbook' or 'Knowledgebase.' Intranets usually had a basic social media 'wall' called the Water Cooler, which was implemented with another custom plugin I made. We would often also include a Contacts directory page, using a third-party WP Contacts plugin.",
  "order": 10
},
{
  "directory": "agentsalliance-intranetexperts-com",
  "images": {
    "featuredThumbnail": "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com_thumb.png",
    "thumbnails": [ 
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com_thumb.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-library_thumb.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-contacts_thumb.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-water-cooler_thumb.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-carrier-info-dashboard_thumb.png"
      ],
    "cropped": [
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com_cropped-1920x1280.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-library_cropped-1920x1280.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-contacts_cropped-1920x1280.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-water-cooler_cropped-1920x1280.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-carrier-info-dashboard_cropped-1920x1280.png"
      ],
    "featuredFull": "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com.png",
    "full": [
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-library.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-contacts.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-water-cooler.png",
      "agentsalliance-intranetexperts-com/agentsalliance-intranetexperts-com-carrier-info-dashboard.png"
      ]
  },
  "url": "agentsalliance.intranetexperts.com/",
  "showLink" : false,
  "title": "Agents Alliance Intranet", 
  "tags": ["Intranet Site", "Custom Email Notifications Plugin", "AWS SES", "Library Plugin", "Google Search Plugin", "Water Cooler Social Plugin", "Custom Theme", "WordPress"],
  "description": "Agents Alliance Intranet is a company intranet and an example of one of our Intranet Experts sites. Intranet Experts was a division of Awesurance that built WordPress-based company intranets as an internal tool to post news, collect documents, standard operating procedures, training tools, videos, social interactions, and generally aggregate company resources all in one place. Each Intranet typically has one or more version of our custom Library Plugin that I made which adds a custom post type with custom archives for easily browsing and navigating articles. (Sometimes called other things at the client's request such as 'Playbook' or 'Knowledgebase.' Intranets usually had a basic social media 'wall' called the Water Cooler, which was implemented with another custom plugin I made. We would often also include a Contacts directory page, using a third-party WP Contacts plugin.",
  "order": 10
},
{
  "directory": "demo-intranetexperts-com",
  "images": {
    "featuredThumbnail": "demo-intranetexperts-com/demo-intranetexperts-com_thumb.png",
    "thumbnails": [ 
      "demo-intranetexperts-com/demo-intranetexperts-com_thumb.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-news_thumb.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-library_thumb.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-contacts_thumb.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-water-cooler_thumb.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-wp-login-php_thumb.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-tools-large-file-upload_thumb.png"
      ],
    "cropped": [
      "demo-intranetexperts-com/demo-intranetexperts-com_cropped-1920x1280.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-news_cropped-1920x1280.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-library_cropped-1920x1280.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-contacts_cropped-1920x1280.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-water-cooler_cropped-1920x1280.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-wp-login-php_cropped-1920x1280.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-tools-large-file-upload_cropped-1920x1280.png"
      ],
    "featuredFull": "demo-intranetexperts-com/demo-intranetexperts-com.png",
    "full": [
      "demo-intranetexperts-com/demo-intranetexperts-com.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-news.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-library.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-contacts.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-water-cooler.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-wp-login-php.png",
      "demo-intranetexperts-com/demo-intranetexperts-com-tools-large-file-upload.png"
      ]
  },
  "url": "demo.intranetexperts.com/",
  "showLink" : false,
  "title": "Intranet Experts Demo", 
  "tags": ["Intranet Site", "Demo", "Custom Theme", "Library Plugin", "Google Search Plugin", "Water Cooler Social Plugin", "Suggestions Plugin", "WordPress"],
  "description": "This is a stripped-down demo site used to demonstrate basic features of our Intranet Experts sites. Intranet Experts was a division of Awesurance that built WordPress-based company intranets as an internal tool to post news, collect documents, standard operating procedures, training tools, videos, social interactions, and generally aggregate company resources all in one place. Each Intranet typically has one or more version of our custom Library Plugin that I made which adds a custom post type with custom archives for easily browsing and navigating articles. (Sometimes called other things at the client's request such as 'Playbook' or 'Knowledgebase.' Intranets usually had a basic social media 'wall' called the Water Cooler, which was implemented with another custom plugin I made. We would often also include a Contacts directory page, using a third-party WP Contacts plugin.",
  "order": 14
},
{
  "directory": "hub-connectsai-com",
  "images": {
    "featuredThumbnail": "hub-connectsai-com/hub-connectsai-com-home_thumb.png",
    "thumbnails": [ 
      "hub-connectsai-com/hub-connectsai-com-home_thumb.png",
      "hub-connectsai-com/hub-connectsai-com-groups_thumb.png",
      "hub-connectsai-com/hub-connectsai-com-stratspace_thumb.png",
      "hub-connectsai-com/hub-connectsai-com-knowledge-base_thumb.png"
      ],
    "cropped": [
      "hub-connectsai-com/hub-connectsai-com-home_cropped-1920x1280.png",
      "hub-connectsai-com/hub-connectsai-com-groups_cropped-1920x1280.png",
      "hub-connectsai-com/hub-connectsai-com-stratspace_cropped-1920x1280.png",
      "hub-connectsai-com/hub-connectsai-com-knowledge-base_cropped-1920x1280.png"
      ],
    "featuredFull": "hub-connectsai-com/hub-connectsai-com-home.png",
    "full": [
      "hub-connectsai-com/hub-connectsai-com-home.png",
      "hub-connectsai-com/hub-connectsai-com-groups.png",
      "hub-connectsai-com/hub-connectsai-com-stratspace.png",
      "hub-connectsai-com/hub-connectsai-com-knowledge-base.png"
      ]
  },
  "url": "hub.connectsai.com/",
  "showLink" : false,
  "title": "The Hub by Stratton Agency", 
  "tags": ["Intranet Site", "Google Search Plugin", "Custom Theme", "WordPress"],
  "description": "The Hub by Stratton Agency was an early example of one of our Intranet Experts sites. Intranet Experts was a division of Awesurance that built WordPress-based company intranets as an internal tool to post news, collect documents, standard operating procedures, training tools, videos, social interactions, and generally aggregate company resources all in one place. Each Intranet typically has one or more version of our custom Library Plugin that I made which adds a custom post type with custom archives for easily browsing and navigating articles. (Sometimes called other things at the client's request such as 'Playbook' or 'Knowledgebase.' Intranets usually had a basic social media 'wall' called the Water Cooler, which was implemented with another custom plugin I made. We would often also include a Contacts directory page, using a third-party WP Contacts plugin.",
  "order": 14
},
{
  "directory": "hanbyhub-com",
  "images": {
    "featuredThumbnail": "hanbyhub-com/hanbyhub-com_thumb.png",
    "thumbnails": [ 
      "hanbyhub-com/hanbyhub-com_thumb.png",
      "hanbyhub-com/hanbyhub-com-carrier_thumb.png",
      "hanbyhub-com/hanbyhub-com-library_thumb.png",
      "hanbyhub-com/hanbyhub-com-contacts_thumb.png"
      ],
    "cropped": [
      "hanbyhub-com/hanbyhub-com_cropped-1920x1280.png",
      "hanbyhub-com/hanbyhub-com-carrier_cropped-1920x1280.png",
      "hanbyhub-com/hanbyhub-com-library_cropped-1920x1280.png",
      "hanbyhub-com/hanbyhub-com-contacts_cropped-1920x1280.png"
      ],
    "featuredFull": "hanbyhub-com/hanbyhub-com.png",
    "full": [
      "hanbyhub-com/hanbyhub-com.png",
      "hanbyhub-com/hanbyhub-com-carrier.png",
      "hanbyhub-com/hanbyhub-com-library.png",
      "hanbyhub-com/hanbyhub-com-contacts.png"
      ]
  },
  "url": "hanbyhub.com/",
  "showLink" : false,
  "title": "Hanby Hub", 
  "tags": ["Intranet Site", "Library Plugin", "Water Cooler Social Plugin", "Google Search Plugin", "WordPress"],
  "description": "The Hanby Hub is a company intranet and an example of one of our Intranet Experts sites. Intranet Experts was a division of Awesurance that built WordPress-based company intranets as an internal tool to post news, collect documents, standard operating procedures, training tools, videos, social interactions, and generally aggregate company resources all in one place. Each Intranet typically has one or more version of our custom Library Plugin that I made which adds a custom post type with custom archives for easily browsing and navigating articles. (Sometimes called other things at the client's request such as 'Playbook' or 'Knowledgebase.' Intranets usually had a basic social media 'wall' called the Water Cooler, which was implemented with another custom plugin I made. We would often also include a Contacts directory page, using a third-party WP Contacts plugin.",
  "order": 13
},
{
  "directory": "members-mikestromsoe-com",
  "images": {
    "featuredThumbnail": "members-mikestromsoe-com/members-mikestromsoe-com_thumb.png",
    "thumbnails": [ 
      "members-mikestromsoe-com/members-mikestromsoe-com_thumb.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-forums_thumb.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-library_thumb.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-activity_thumb.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-training_thumb.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-members-ronh_thumb.png"
      ],
    "cropped": [
      "members-mikestromsoe-com/members-mikestromsoe-com_cropped-1920x1280.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-forums_cropped-1920x1280.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-library_cropped-1920x1280.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-activity_cropped-1920x1280.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-training_cropped-1920x1280.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-members-ronh_cropped-1920x1280.png"
      ],
    "featuredFull": "members-mikestromsoe-com/members-mikestromsoe-com.png",
    "full": [
      "members-mikestromsoe-com/members-mikestromsoe-com.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-forums.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-library.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-activity.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-training.png",
      "members-mikestromsoe-com/members-mikestromsoe-com-members-ronh.png"
      ]
  },
  "url": "members.mikestromsoe.com/",
  "showLink" : false,
  "title": "Mike Stromsoe Members", 
  "tags": ["Membership Site", "Intranet Site", "Library Plugin", "Custom User Meta Plugin", "Custom Theme", "BuddyPress", "BBPress", "WordPress"],
  "description": "Mike Stromsoe Members is a site that is based on our Intranet Experts sites, but instead of being purely internal, it is a private membership site that Mike Stromsoe had us build for his business coaching clients. It has many additional features added on, such as a calendar, as well as links to / integrations with other Mike Stromsoe sites we have built for his organization. It has a more sophisticated Social section that takes advantage of the BuddyPress and BBPress plugins for interactions & forums. Intranet Experts was a division of Awesurance that built WordPress-based company intranets as an internal tool to post news, collect documents, standard operating procedures, training tools, videos, social interactions, and generally aggregate company resources all in one place. Each Intranet typically has one or more version of our custom Library Plugin that I made which adds a custom post type with custom archives for easily browsing and navigating articles. (Sometimes called other things at the client's request such as 'Playbook' or 'Knowledgebase.' We would often also include a Contacts directory page, using a third-party WP Contacts plugin.",
  "order": 10
},
{
  "directory": "beunstoppablebootcamp2020-mikestromsoe-com",
  "images": {
    "featuredThumbnail": "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com_thumb.png",
    "thumbnails": [ 
      "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com_thumb.png",
      "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com-pricing-options_thumb.png"
      ],
    "cropped": [
      "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com_cropped-1920x1280.png",
      "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com-pricing-options_cropped-1920x1280.png"
      ],
    "featuredFull": "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com.png",
    "full": [
      "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com.png",
      "beunstoppablebootcamp2020-mikestromsoe-com/beunstoppablebootcamp2020-mikestromsoe-com-pricing-options.png"
      ]
  },
  "url": "beunstoppablebootcamp2020.mikestromsoe.com/",
  "showLink" : true,
  "title": "Be Unstoppable Bootcamp 2020", 
  "tags": ["Brochure Site", "Custom Theme", "WordPress"],
  "description": "Be Unstoppable Bootcamp 2020 is the updated version of a simple sales / brochure site for Mike Stromsoe's business bootcamp he hosts each year. It is designed to advertise the event and get new registrations.",
  "order": 30
},
{
  "directory": "products-mikestromsoe-com",
  "images": {
    "featuredThumbnail": "products-mikestromsoe-com/products-mikestromsoe-com_thumb.png",
    "thumbnails": [ 
      "products-mikestromsoe-com/products-mikestromsoe-com_thumb.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-cart_thumb.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-store_thumb.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-dashboard_thumb.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-product-agency-wealth-mastery_thumb.png"
      ],
    "cropped": [
      "products-mikestromsoe-com/products-mikestromsoe-com_cropped-1920x1280.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-cart_cropped-1920x1280.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-store_cropped-1920x1280.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-dashboard_cropped-1920x1280.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-product-agency-wealth-mastery_cropped-1920x1280.png"
      ],
    "featuredFull": "products-mikestromsoe-com/products-mikestromsoe-com.png",
    "full": [
      "products-mikestromsoe-com/products-mikestromsoe-com.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-cart.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-store.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-dashboard.png",
      "products-mikestromsoe-com/products-mikestromsoe-com-product-agency-wealth-mastery.png"
      ]
  },
  "url": "products.mikestromsoe.com/",
  "showLink" : true,
  "title": "UPP Products Store", 
  "tags": ["Online Learning Site", "LearnDash LMS", "WooCommerce", "Custom Theme", "WordPress"],
  "description": "UPP Products Store is an information product site that sells access to online educational courses using the WooCommerce and LearnDash Learning Management System plugins. Once a course is purchased, the user has access to the course materials and can earn badges and certificates upon completion. I built this theme and integrated the third parting plugins",
  "order": 18
},
{
  "directory": "unstoppableprofitproducer-com",
  "images": {
    "featuredThumbnail": "unstoppableprofitproducer-com/unstoppableprofitproducer-com_thumb.png",
    "thumbnails": [ 
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com_thumb.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-coaching_thumb.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-resources_thumb.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-our-mission_thumb.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-speaking-media_thumb.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-clients-speak-out_thumb.png"
      ],
    "cropped": [
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com_cropped-1920x1280.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-coaching_cropped-1920x1280.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-resources_cropped-1920x1280.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-our-mission_cropped-1920x1280.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-speaking-media_cropped-1920x1280.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-clients-speak-out_cropped-1920x1280.png"
      ],
    "featuredFull": "unstoppableprofitproducer-com/unstoppableprofitproducer-com.png",
    "full": [
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-coaching.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-resources.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-our-mission.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-speaking-media.png",
      "unstoppableprofitproducer-com/unstoppableprofitproducer-com-clients-speak-out.png"
      ]
  },
  "url": "unstoppableprofitproducer.com/",
  "showLink" : true,
  "title": "Unstoppable Profit Producer", 
  "tags": ["Consultant Site", "Motivational Speaker Site", "Industry Conference", "Custom Theme", "WordPress"],
  "description": "The Unstoppable Profit Producer site is a main entry point into Mike Stromsoe's ecosystem of sites and products. Mike was a long-time client of Awesurance. We helped him with a variety of products, including a private membership site, an online learning site, an employee recruitment site, a business bootcamp registration site, a podcast site, and other online marketing services. I personally made custom themes and plugins for all the aforementioned several sites, and I helped set them up and administer them from start to finish. This site also dynamically lists the latest podcasts from Mike's podcast site in the footer using a shortcode.",
  "order": 20
},
{
  "directory": "careers-goodwillfinancial-com",
  "images": {
    "featuredThumbnail": "careers-goodwillfinancial-com/careers-goodwillfinancial-com_thumb.png",
    "thumbnails": [ 
      "careers-goodwillfinancial-com/careers-goodwillfinancial-com_thumb.png",
      "careers-goodwillfinancial-com/careers-goodwillfinancial-com-2019-04-23-receptionist-administrative-support_thumb.png"
      ],
    "cropped": [
      "careers-goodwillfinancial-com/careers-goodwillfinancial-com_cropped-1920x1280.png",
      "careers-goodwillfinancial-com/careers-goodwillfinancial-com-2019-04-23-receptionist-administrative-support_cropped-1920x1280.png"
      ],
    "featuredFull": "careers-goodwillfinancial-com/careers-goodwillfinancial-com.png",
    "full": [
      "careers-goodwillfinancial-com/careers-goodwillfinancial-com.png",
      "careers-goodwillfinancial-com/careers-goodwillfinancial-com-2019-04-23-receptionist-administrative-support.png"
      ]
  },
  "url": "careers.goodwillfinancial.com/",
  "showLink" : true,
  "title": "Careers at Goodwill Financial", 
  "tags": ["Brochure Site", "Custom Theme", "WordPress"],
  "description": "Careers at Goodwill Financial is a simple site designed to advertise open positions with the company and accept applications online. I have built a series of these sites for different clients.",
  "order": 31
},
{
  "directory": "careers-stromsoeinsurance-com",
  "images": {
    "featuredThumbnail": "careers-stromsoeinsurance-com/careers-stromsoeinsurance-com_thumb.png",
    "thumbnails": [ 
      "careers-stromsoeinsurance-com/careers-stromsoeinsurance-com_thumb.png"
      ],
    "cropped": [
      "careers-stromsoeinsurance-com/careers-stromsoeinsurance-com_cropped-1920x1280.png"
      ],
    "featuredFull": "careers-stromsoeinsurance-com/careers-stromsoeinsurance-com.png",
    "full": [
      "careers-stromsoeinsurance-com/careers-stromsoeinsurance-com.png"
      ]
  },
  "url": "careers.stromsoeinsurance.com/",
  "showLink" : true,
  "title": "Careers at Stromsoe Insurance", 
  "tags": ["Brochure Site", "Custom Theme", "WordPress"],
  "description": "Careers at Stromsoe Insurance is a simple site designed to advertise open positions with the company and accept applications online. I have built a series of these sites for different clients.",
  "order": 32
},
{
  "directory": "homecareshield-com",
  "images": {
    "featuredThumbnail": "homecareshield-com/homecareshield-com_thumb.png",
    "thumbnails": [ 
      "homecareshield-com/homecareshield-com_thumb.png",
      "homecareshield-com/homecareshield-com-about_thumb.png",
      "homecareshield-com/homecareshield-com-contact_thumb.png",
      "homecareshield-com/homecareshield-com-testimonials_thumb.png",
      "homecareshield-com/homecareshield-com-resources-faqs_thumb.png",
      "homecareshield-com/homecareshield-com-services-insurance-placement_thumb.png",
      "homecareshield-com/homecareshield-com-2017-11-30-protect-your-business-when-using-social-media_thumb.png"
      ],
    "cropped": [
      "homecareshield-com/homecareshield-com_cropped-1920x1280.png",
      "homecareshield-com/homecareshield-com-about_cropped-1920x1280.png",
      "homecareshield-com/homecareshield-com-contact_cropped-1920x1280.png",
      "homecareshield-com/homecareshield-com-testimonials_cropped-1920x1280.png",
      "homecareshield-com/homecareshield-com-resources-faqs_cropped-1920x1280.png",
      "homecareshield-com/homecareshield-com-services-insurance-placement_cropped-1920x1280.png",
      "homecareshield-com/homecareshield-com-2017-11-30-protect-your-business-when-using-social-media_cropped-1920x1280.png"
      ],
    "featuredFull": "homecareshield-com/homecareshield-com.png",
    "full": [
      "homecareshield-com/homecareshield-com.png",
      "homecareshield-com/homecareshield-com-about.png",
      "homecareshield-com/homecareshield-com-contact.png",
      "homecareshield-com/homecareshield-com-testimonials.png",
      "homecareshield-com/homecareshield-com-resources-faqs.png",
      "homecareshield-com/homecareshield-com-services-insurance-placement.png",
      "homecareshield-com/homecareshield-com-2017-11-30-protect-your-business-when-using-social-media.png"
      ]
  },
  "url": null,
  "showLink" : false,
  "title": "Homecare Shield", 
  "tags": ["Agency Site", "Custom Theme", "WordPress"],
  "description": "Homecare Shield was an agency-style site designed to advertise an insurance agency's services in the homecare insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 50
},
{
  "directory": "cellbeat-net",
  "images": {
    "featuredThumbnail": "cellbeat-net/cellbeat-net_thumb.png",
    "thumbnails": [ 
      "cellbeat-net/cellbeat-net_thumb.png",
      "cellbeat-net/cellbeat-net-shop_thumb.png",
      "cellbeat-net/cellbeat-net-getfailover_thumb.png"
      ],
    "cropped": [
      "cellbeat-net/cellbeat-net_cropped-1920x1280.png",
      "cellbeat-net/cellbeat-net-shop_cropped-1920x1280.png",
      "cellbeat-net/cellbeat-net-getfailover_cropped-1920x1280.png"
      ],
    "featuredFull": "cellbeat-net/cellbeat-net.png",
    "full": [
      "cellbeat-net/cellbeat-net.png",
      "cellbeat-net/cellbeat-net-shop.png",
      "cellbeat-net/cellbeat-net-getfailover.png"
      ]
  },
  "url": "cellbeat.net/",
  "showLink" : true,
  "title": "Cellbeat", 
  "tags": ["Ecommerce Site", "WooCommerce", "Custom Theme", "WordPress"],
  "description": "Cellbeat is a business that specializes in keeping high-network-traffic businesses online. This is an e-commerce site designed to advertise their expertise & services as well as sell their inventory of network devices online. This site uses a custom theme and is built with WooCommerce.",
  "order": 25
},
{
  "directory": "livingwelltherapy-com",
  "images": {
    "featuredThumbnail": "livingwelltherapy-com/livingwelltherapy-com_thumb.png",
    "thumbnails": [ 
      "livingwelltherapy-com/livingwelltherapy-com_thumb.png",
      "livingwelltherapy-com/livingwelltherapy-com-about_thumb.png",
      "livingwelltherapy-com/livingwelltherapy-com-patients-testimonials_thumb.png",
      "livingwelltherapy-com/livingwelltherapy-com-services-physical-therapy_thumb.png",
      "livingwelltherapy-com/livingwelltherapy-com-specialties-athletic-sports-injuries_thumb.png"
      ],
    "cropped": [
      "livingwelltherapy-com/livingwelltherapy-com_cropped-1920x1280.png",
      "livingwelltherapy-com/livingwelltherapy-com-about_cropped-1920x1280.png",
      "livingwelltherapy-com/livingwelltherapy-com-patients-testimonials_cropped-1920x1280.png",
      "livingwelltherapy-com/livingwelltherapy-com-services-physical-therapy_cropped-1920x1280.png",
      "livingwelltherapy-com/livingwelltherapy-com-specialties-athletic-sports-injuries_cropped-1920x1280.png"
      ],
    "featuredFull": "livingwelltherapy-com/livingwelltherapy-com.png",
    "full": [
      "livingwelltherapy-com/livingwelltherapy-com.png",
      "livingwelltherapy-com/livingwelltherapy-com-about.png",
      "livingwelltherapy-com/livingwelltherapy-com-patients-testimonials.png",
      "livingwelltherapy-com/livingwelltherapy-com-services-physical-therapy.png",
      "livingwelltherapy-com/livingwelltherapy-com-specialties-athletic-sports-injuries.png"
      ]
  },
  "url": "livingwelltherapy.com/",
  "showLink" : true,
  "title": "Living Well Therapy", 
  "tags": ["Agency Site", "Review Ticker Plugin", "Custom Theme", "WordPress"],
  "description": "Living Well Therapy is an agency-style site made for a physical therapy office. I set up the site, made the custom theme, and styled it. After the site was built, the client requested the ability to add a rotating testimonials widget, similar to one he had seen elsewhere. While there are other testimonials plugins, most of them did not fit exactly what the client was looking for, so I made a custom testimonials plugin for this site called the Review Ticker Plugin. It adds a custom post type, has some basic options, and can be embedded anywhere and easily styled to match the rest of the site.",
  "order": 20
},
{
  "directory": "butlervetinsurance-com",
  "images": {
    "featuredThumbnail": "butlervetinsurance-com/butlervetinsurance-com_thumb.png",
    "thumbnails": [ 
      "butlervetinsurance-com/butlervetinsurance-com_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-blog_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-process_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-about-us_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-benefits_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-services_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-testimonials_thumb.png",
      "butlervetinsurance-com/butlervetinsurance-com-2018-11-14-butler-vet-insurance_thumb.png"
      ],
    "cropped": [
      "butlervetinsurance-com/butlervetinsurance-com_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-blog_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-process_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-about-us_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-benefits_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-services_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-testimonials_cropped-1920x1280.png",
      "butlervetinsurance-com/butlervetinsurance-com-2018-11-14-butler-vet-insurance_cropped-1920x1280.png"
      ],
    "featuredFull": "butlervetinsurance-com/butlervetinsurance-com.png",
    "full": [
      "butlervetinsurance-com/butlervetinsurance-com.png",
      "butlervetinsurance-com/butlervetinsurance-com-blog.png",
      "butlervetinsurance-com/butlervetinsurance-com-process.png",
      "butlervetinsurance-com/butlervetinsurance-com-about-us.png",
      "butlervetinsurance-com/butlervetinsurance-com-benefits.png",
      "butlervetinsurance-com/butlervetinsurance-com-services.png",
      "butlervetinsurance-com/butlervetinsurance-com-testimonials.png",
      "butlervetinsurance-com/butlervetinsurance-com-2018-11-14-butler-vet-insurance.png"
      ]
  },
  "url": "butlervetinsurance.com/",
  "showLink" : true,
  "title": "Butler Vet Insurance", 
  "tags": ["Agency Site", "Custom Theme", "WordPress"],
  "description": "Butler Vet Insurance is an agency-style site designed to advertise an insurance agency's services in the veterinary services insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 20
},
{
  "directory": "smdinsurance-com",
  "images": {
    "featuredThumbnail": "smdinsurance-com/smdinsurance-com_thumb.png",
    "thumbnails": [ 
      "smdinsurance-com/smdinsurance-com_thumb.png",
      "smdinsurance-com/smdinsurance-com-contact_thumb.png",
      "smdinsurance-com/smdinsurance-com-auto-insurance_thumb.png",
      "smdinsurance-com/smdinsurance-com-service-center_thumb.png",
      "smdinsurance-com/smdinsurance-com-homeowners-insurance_thumb.png",
      "smdinsurance-com/smdinsurance-com-frequently-asked-questions_thumb.png",
      "smdinsurance-com/smdinsurance-com-general-liability-insurance_thumb.png",
      "smdinsurance-com/smdinsurance-com-workers-compensation-insurance_thumb.png"
      ],
    "cropped": [
      "smdinsurance-com/smdinsurance-com_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-contact_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-auto-insurance_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-service-center_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-homeowners-insurance_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-frequently-asked-questions_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-general-liability-insurance_cropped-1920x1280.png",
      "smdinsurance-com/smdinsurance-com-workers-compensation-insurance_cropped-1920x1280.png"
      ],
    "featuredFull": "smdinsurance-com/smdinsurance-com.png",
    "full": [
      "smdinsurance-com/smdinsurance-com.png",
      "smdinsurance-com/smdinsurance-com-contact.png",
      "smdinsurance-com/smdinsurance-com-auto-insurance.png",
      "smdinsurance-com/smdinsurance-com-service-center.png",
      "smdinsurance-com/smdinsurance-com-homeowners-insurance.png",
      "smdinsurance-com/smdinsurance-com-frequently-asked-questions.png",
      "smdinsurance-com/smdinsurance-com-general-liability-insurance.png",
      "smdinsurance-com/smdinsurance-com-workers-compensation-insurance.png"
      ]
  },
  "url": "smdinsurance.com/",
  "showLink" : true,
  "title": "SMD Insurance", 
  "tags": ["Agency Site", "Custom Theme", "WordPress"],
  "description": "SMD Insurance is an agency-style site designed to advertise an insurance agency's general services. Over the years, I have built dozens of agency-style insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 23
},
{
  "directory": "coremarkins-com",
  "images": {
    "featuredThumbnail": "coremarkins-com/coremarkins-com_thumb.png",
    "thumbnails": [ 
      "coremarkins-com/coremarkins-com_thumb.png",
      "coremarkins-com/coremarkins-com-about-us-blog_thumb.png",
      "coremarkins-com/coremarkins-com-insurance-benefits_thumb.png",
      "coremarkins-com/coremarkins-com-about-us-meet-coremark_thumb.png",
      "coremarkins-com/coremarkins-com-about-us-coremark-cares_thumb.png",
      "coremarkins-com/coremarkins-com-services-account-services_thumb.png",
      "coremarkins-com/coremarkins-com-about-us-frequently-asked-questions_thumb.png",
      "coremarkins-com/coremarkins-com-congress-eliminates-the-cadillac-and-other-aca-taxes_thumb.png"
      ],
    "cropped": [
      "coremarkins-com/coremarkins-com_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-about-us-blog_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-insurance-benefits_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-about-us-meet-coremark_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-about-us-coremark-cares_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-services-account-services_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-about-us-frequently-asked-questions_cropped-1920x1280.png",
      "coremarkins-com/coremarkins-com-congress-eliminates-the-cadillac-and-other-aca-taxes_cropped-1920x1280.png"
      ],
    "featuredFull": "coremarkins-com/coremarkins-com.png",
    "full": [
      "coremarkins-com/coremarkins-com.png",
      "coremarkins-com/coremarkins-com-about-us-blog.png",
      "coremarkins-com/coremarkins-com-insurance-benefits.png",
      "coremarkins-com/coremarkins-com-about-us-meet-coremark.png",
      "coremarkins-com/coremarkins-com-about-us-coremark-cares.png",
      "coremarkins-com/coremarkins-com-services-account-services.png",
      "coremarkins-com/coremarkins-com-about-us-frequently-asked-questions.png",
      "coremarkins-com/coremarkins-com-congress-eliminates-the-cadillac-and-other-aca-taxes.png"
      ]
  },
  "url": "coremarkins.com/",
  "showLink" : true,
  "title": "Coremark Insurance", 
  "tags": ["Agency Site", "AWS Large File Upload Plugin", "AWS SES", "AWS S3", "Custom Theme", "WordPress"],
  "description": "Coremark Insurance is an agency-style site designed to advertise an insurance agency's general services. After it was built, the client requested an easier way to have clients upload large files (such as forms or images that were bigger than the max size limit typically allowed by our hosting). So I built an internal plugin called the AWS Large File Upload Plugin which utilize dropzone.js, Amazon Web Services S3, and AWS SES to allow a file to be dragged and dropped, uploaded to Amazon Web Services, and email a notification with a download link that expires to the company email inbox. The repository for this plugin is available publicly at: https://github.com/GitarMan/aws-file-upload/",
  "order": 23
},
{
  "directory": "desandoins-com",
  "images": {
    "featuredThumbnail": "desandoins-com/desandoins-com_thumb.png",
    "thumbnails": [ 
      "desandoins-com/desandoins-com_thumb.png",
      "desandoins-com/desandoins-com-blog_thumb.png",
      "desandoins-com/desandoins-com-service-center_thumb.png",
      "desandoins-com/desandoins-com-frequently-asked-questions_thumb.png",
      "desandoins-com/desandoins-com-business-property-insurance_thumb.png"
      ],
    "cropped": [
      "desandoins-com/desandoins-com_cropped-1920x1280.png",
      "desandoins-com/desandoins-com-blog_cropped-1920x1280.png",
      "desandoins-com/desandoins-com-service-center_cropped-1920x1280.png",
      "desandoins-com/desandoins-com-frequently-asked-questions_cropped-1920x1280.png",
      "desandoins-com/desandoins-com-business-property-insurance_cropped-1920x1280.png"
      ],
    "featuredFull": "desandoins-com/desandoins-com.png",
    "full": [
      "desandoins-com/desandoins-com.png",
      "desandoins-com/desandoins-com-blog.png",
      "desandoins-com/desandoins-com-service-center.png",
      "desandoins-com/desandoins-com-frequently-asked-questions.png",
      "desandoins-com/desandoins-com-business-property-insurance.png"
      ]
  },
  "url": "desandoins.com/",
  "showLink" : true,
  "title": "Desando Insurance", 
  "tags": ["Agency Site", "Custom Theme", "WordPress"],
  "description": "Desando Insurance is an agency-style site designed to advertise an insurance agency's general services. Over the years, I have built dozens of agency-style insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 24
},
{
  "directory": "mahanrm-com",
  "images": {
    "featuredThumbnail": "mahanrm-com/mahanrm-com_thumb.png",
    "thumbnails": [ 
      "mahanrm-com/mahanrm-com_thumb.png"
      ],
    "cropped": [
      "mahanrm-com/mahanrm-com_cropped-1920x1280.png"
      ],
    "featuredFull": "mahanrm-com/mahanrm-com.png",
    "full": [
      "mahanrm-com/mahanrm-com.png"
      ]
  },
  "url": "mahanrm.com/",
  "showLink" : true,
  "title": "Mahan Risk Management", 
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Mahan Risk Management is an agency-style site designed to advertise an insurance agency's general services. Over the years, I have built dozens of agency-style insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 25
},
{
  "directory": "modabinsurance-com",
  "images": {
    "featuredThumbnail": "modabinsurance-com/modabinsurance-com_thumb.png",
    "thumbnails": [ 
      "modabinsurance-com/modabinsurance-com_thumb.png",
      "modabinsurance-com/modabinsurance-com-life_thumb.png",
      "modabinsurance-com/modabinsurance-com-about-us_thumb.png",
      "modabinsurance-com/modabinsurance-com-personal_thumb.png",
      "modabinsurance-com/modabinsurance-com-support-center-report-a-claim_thumb.png",
      "modabinsurance-com/modabinsurance-com-support-center-frequently-asked-questions_thumb.png"
      ],
    "cropped": [
      "modabinsurance-com/modabinsurance-com_cropped-1920x1280.png",
      "modabinsurance-com/modabinsurance-com-life_cropped-1920x1280.png",
      "modabinsurance-com/modabinsurance-com-about-us_cropped-1920x1280.png",
      "modabinsurance-com/modabinsurance-com-personal_cropped-1920x1280.png",
      "modabinsurance-com/modabinsurance-com-support-center-report-a-claim_cropped-1920x1280.png",
      "modabinsurance-com/modabinsurance-com-support-center-frequently-asked-questions_cropped-1920x1280.png"
      ],
    "featuredFull": "modabinsurance-com/modabinsurance-com.png",
    "full": [
      "modabinsurance-com/modabinsurance-com.png",
      "modabinsurance-com/modabinsurance-com-life.png",
      "modabinsurance-com/modabinsurance-com-about-us.png",
      "modabinsurance-com/modabinsurance-com-personal.png",
      "modabinsurance-com/modabinsurance-com-commercial.png",
      "modabinsurance-com/modabinsurance-com-support-center-report-a-claim.png",
      "modabinsurance-com/modabinsurance-com-support-center-frequently-asked-questions.png"
      ]
  },
  "url": "modabinsurance.com/",
  "showLink" : true,
  "title": "Modab Insurance Services", 
  "tags": ["Agency Site", "Custom Theme", "WordPress"],
  "description": "Modab Insurance Services is an agency-style site designed to advertise an insurance agency's general services. Over the years, I have built dozens of agency-style insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated). I also took the photo of Yosemite Valley that is used on the homepage.",
  "order": 25
},
{
  "directory": "insuremyautoshop-com",
  "images": {
    "featuredThumbnail": "insuremyautoshop-com/insuremyautoshop-com_thumb.png",
    "thumbnails": [ 
      "insuremyautoshop-com/insuremyautoshop-com_thumb.png"
      ],
    "cropped": [
      "insuremyautoshop-com/insuremyautoshop-com_cropped-1920x1280.png"
      ],
    "featuredFull": "insuremyautoshop-com/insuremyautoshop-com.png",
    "full": [
      "insuremyautoshop-com/insuremyautoshop-com.png"
      ]
  },
  "url": "insuremyautoshop.com/",
  "showLink" : true,
  "title": "Insure My Auto Shop",
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Insure My Auto Shop is an agency-style site designed to advertise an insurance agency's services in the automotive services insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 23
},
{
  "directory": "protectmybrewery-com",
  "images": {
    "featuredThumbnail": "protectmybrewery-com/protectmybrewery-com_thumb.png",
    "thumbnails": [ 
      "protectmybrewery-com/protectmybrewery-com_thumb.png"
      ],
    "cropped": [
      "protectmybrewery-com/protectmybrewery-com_cropped-1920x1280.png"
      ],
    "featuredFull": "protectmybrewery-com/protectmybrewery-com.png",
    "full": [
      "protectmybrewery-com/protectmybrewery-com.png"
      ]
  },
  "url": "protectmybrewery.com/",
  "showLink" : true,
  "title": "Protect My Brewery", 
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Protect My Brewery is an agency-style site designed to advertise an insurance agency's services in the brewery insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 22
},
{
  "directory": "protectmydaycare-janasko-com",
  "images": {
    "featuredThumbnail": "protectmydaycare-janasko-com/protectmydaycare-janasko-com_thumb.png",
    "thumbnails": [ 
      "protectmydaycare-janasko-com/protectmydaycare-janasko-com_thumb.png"
      ],
    "cropped": [
      "protectmydaycare-janasko-com/protectmydaycare-janasko-com_cropped-1920x1280.png"
      ],
    "featuredFull": "protectmydaycare-janasko-com/protectmydaycare-janasko-com.png",
    "full": [
      "protectmydaycare-janasko-com/protectmydaycare-janasko-com.png"
      ]
  },
  "url": "protectmydaycare.janasko.com/",
  "showLink" : true,
  "title": "Protect My Daycare", 
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Protect My Daycare is an agency-style site designed to advertise an insurance agency's services in the daycare insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 22
},
{
  "directory": "protectmyteendriver-janasko-com",
  "images": {
    "featuredThumbnail": "protectmyteendriver-janasko-com/protectmyteendriver-janasko-com_thumb.png",
    "thumbnails": [ 
      "protectmyteendriver-janasko-com/protectmyteendriver-janasko-com_thumb.png"
      ],
    "cropped": [
      "protectmyteendriver-janasko-com/protectmyteendriver-janasko-com_cropped-1920x1280.png"
      ],
    "featuredFull": "protectmyteendriver-janasko-com/protectmyteendriver-janasko-com.png",
    "full": [
      "protectmyteendriver-janasko-com/protectmyteendriver-janasko-com.png"
      ]
  },
  "url": "protectmyteendriver.janasko.com/",
  "showLink" : true,
  "title": "Protect My Teen Driver", 
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Protect My Teen Driver is an agency-style site designed to advertise an insurance agency's services in the teen driver insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 22
},
{
  "directory": "treetriminsurance-com",
  "images": {
    "featuredThumbnail": "treetriminsurance-com/treetriminsurance-com_thumb.png",
    "thumbnails": [ 
      "treetriminsurance-com/treetriminsurance-com_thumb.png"
      ],
    "cropped": [
      "treetriminsurance-com/treetriminsurance-com_cropped-1920x1280.png"
      ],
    "featuredFull": "treetriminsurance-com/treetriminsurance-com.png",
    "full": [
      "treetriminsurance-com/treetriminsurance-com.png"
      ]
  },
  "url": "treetriminsurance.com/",
  "showLink" : true,
  "title": "Tree Trim Insurance", 
  "tags": ["Agency Site", "Third Party Theme", "WordPress"],
  "description": "Tree Trim Insurance is an agency-style site designed to advertise an insurance agency's services in the tree trimming insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 90
},
{
  "directory": "arguellocatering-joshnadler-com",
  "images": {
    "featuredThumbnail": "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com_thumb.png",
    "thumbnails": [ 
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com_thumb.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-menu_thumb.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-contact_thumb.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-gallery_thumb.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-profile-arguellocatering_thumb.png"
      ],
    "cropped": [
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com_cropped-1920x1280.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-menu_cropped-1920x1280.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-contact_cropped-1920x1280.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-gallery_cropped-1920x1280.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-profile-arguellocatering_cropped-1920x1280.png"
      ],
    "featuredFull": "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com.png",
    "full": [
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-menu.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-contact.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-gallery.png",
      "arguellocatering-joshnadler-com/arguellocatering-joshnadler-com-profile-arguellocatering.png"
      ]
  },
  "url": "arguellocatering.joshnadler.com/",
  "showLink" : true,
  "title": "Arguello Catering", 
  "tags": ["Restaurant Site", "Third Party Theme", "WordPress"],
  "description": "This is one of the few sites we built at Awesurance where we did not use a custom theme. Our main niche as a web development & online marketing agency was working with independent insurance agencies. In order to keep within the client's budget, we found a well-made theme that already had the main features they were looking for called Foodpicky which worked with WooCommerce. With this site I set it up and helped configure all the settings.",
  "order": 90
},
{
  "directory": "contractorsinsuranceagency-com",
  "images": {
    "featuredThumbnail": "contractorsinsuranceagency-com/contractorsinsuranceagency-com_thumb.png",
    "thumbnails": [ 
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com_thumb.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-blog_thumb.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-about_thumb.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-contact_thumb.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-services_thumb.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-testimonials_thumb.png"
      ],
    "cropped": [
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com_cropped-1920x1280.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-blog_cropped-1920x1280.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-about_cropped-1920x1280.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-contact_cropped-1920x1280.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-services_cropped-1920x1280.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-testimonials_cropped-1920x1280.png"
      ],
    "featuredFull": "contractorsinsuranceagency-com/contractorsinsuranceagency-com.png",
    "full": [
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-blog.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-about.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-contact.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-services.png",
      "contractorsinsuranceagency-com/contractorsinsuranceagency-com-testimonials.png"
      ]
  },
  "url": "contractorsinsuranceagency.com/",
  "showLink" : true,
  "title": "Contractors Insurance Agency", 
  "tags": ["Agency Site", "Third Party Theme", "WordPress"],
  "description": "Contractors Insurance Agency is one of the few sites we built at Awesurance where we did not use a custom theme. I believe the client liked the look and feel of one of our older client sites that used the Salient theme which already had the main features they were looking for. With this site I set it up and helped configure all the settings.",
  "order": 91
},
{
  "directory": "shashainsurance-com",
  "images": {
    "featuredThumbnail": "shashainsurance-com/shashainsurance-com_thumb.png",
    "thumbnails": [ 
      "shashainsurance-com/shashainsurance-com_thumb.png",
      "shashainsurance-com/shashainsurance-com-about_thumb.png",
      "shashainsurance-com/shashainsurance-com-survey_thumb.png",
      "shashainsurance-com/shashainsurance-com-auto-insurance_thumb.png",
      "shashainsurance-com/shashainsurance-com-service-center_thumb.png",
      "shashainsurance-com/shashainsurance-com-frequently-asked-questions_thumb.png",
      "shashainsurance-com/shashainsurance-com-general-liability-insurance_thumb.png"
      ],
    "cropped": [
      "shashainsurance-com/shashainsurance-com_cropped-1920x1280.png",
      "shashainsurance-com/shashainsurance-com-about_cropped-1920x1280.png",
      "shashainsurance-com/shashainsurance-com-survey_cropped-1920x1280.png",
      "shashainsurance-com/shashainsurance-com-auto-insurance_cropped-1920x1280.png",
      "shashainsurance-com/shashainsurance-com-service-center_cropped-1920x1280.png",
      "shashainsurance-com/shashainsurance-com-frequently-asked-questions_cropped-1920x1280.png",
      "shashainsurance-com/shashainsurance-com-general-liability-insurance_cropped-1920x1280.png"
      ],
    "featuredFull": "shashainsurance-com/shashainsurance-com.png",
    "full": [
      "shashainsurance-com/shashainsurance-com.png",
      "shashainsurance-com/shashainsurance-com-about.png",
      "shashainsurance-com/shashainsurance-com-survey.png",
      "shashainsurance-com/shashainsurance-com-auto-insurance.png",
      "shashainsurance-com/shashainsurance-com-service-center.png",
      "shashainsurance-com/shashainsurance-com-frequently-asked-questions.png",
      "shashainsurance-com/shashainsurance-com-general-liability-insurance.png"
      ]
  },
  "url": "shashainsurance.com/",
  "showLink" : true,
  "title": "Shasha Insurance", 
  "tags": ["Agency Site", "Custom Theme", "WordPress"],
  "description": "Shasha Insurance Services is an agency-style site designed to advertise an insurance agency's general services. Over the years, I have built dozens of agency-style insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 23
},
{
  "directory": "tucsoninsurance-com",
  "images": {
    "featuredThumbnail": "tucsoninsurance-com/tucsoninsurance-com_thumb.png",
    "thumbnails": [ 
      "tucsoninsurance-com/tucsoninsurance-com_thumb.png",
      "tucsoninsurance-com/tucsoninsurance-com-blog-2019-09-23-auto-owners-insurance-from-your-az-independent-agent_thumb.png"
      ],
    "cropped": [
      "tucsoninsurance-com/tucsoninsurance-com_cropped-1920x1280.png",
      "tucsoninsurance-com/tucsoninsurance-com-blog-2019-09-23-auto-owners-insurance-from-your-az-independent-agent_cropped-1920x1280.png"
      ],
    "featuredFull": "tucsoninsurance-com/tucsoninsurance-com.png",
    "full": [
      "tucsoninsurance-com/tucsoninsurance-com.png",
      "tucsoninsurance-com/tucsoninsurance-com-blog-2019-09-23-auto-owners-insurance-from-your-az-independent-agent.png"
      ]
  },
  "url": "tucsoninsurance.com/",
  "showLink" : true,
  "title": "Tucson Insurance", 
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Tucson Insurance is an agency-style site designed to advertise an insurance agency's general services. Over the years, I have built dozens of agency-style insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 28
},
{
  "directory": "johnscottinsurance-com",
  "images": {
    "featuredThumbnail": "johnscottinsurance-com/johnscottinsurance-com_thumb.png",
    "thumbnails": [ 
      "johnscottinsurance-com/johnscottinsurance-com_thumb.png",
      "johnscottinsurance-com/johnscottinsurance-com-our-story_thumb.png",
      "johnscottinsurance-com/johnscottinsurance-com-testimonials_thumb.png",
      "johnscottinsurance-com/johnscottinsurance-com-blog-jsi-blog_thumb.png",
      "johnscottinsurance-com/johnscottinsurance-com-account-review_thumb.png",
      "johnscottinsurance-com/johnscottinsurance-com-business-insurance-michigan_thumb.png"
      ],
    "cropped": [
      "johnscottinsurance-com/johnscottinsurance-com_cropped-1920x1280.png",
      "johnscottinsurance-com/johnscottinsurance-com-our-story_cropped-1920x1280.png",
      "johnscottinsurance-com/johnscottinsurance-com-testimonials_cropped-1920x1280.png",
      "johnscottinsurance-com/johnscottinsurance-com-blog-jsi-blog_cropped-1920x1280.png",
      "johnscottinsurance-com/johnscottinsurance-com-account-review_cropped-1920x1280.png",
      "johnscottinsurance-com/johnscottinsurance-com-business-insurance-michigan_cropped-1920x1280.png"
      ],
    "featuredFull": "johnscottinsurance-com/johnscottinsurance-com.png",
    "full": [
      "johnscottinsurance-com/johnscottinsurance-com.png",
      "johnscottinsurance-com/johnscottinsurance-com-our-story.png",
      "johnscottinsurance-com/johnscottinsurance-com-testimonials.png",
      "johnscottinsurance-com/johnscottinsurance-com-blog-jsi-blog.png",
      "johnscottinsurance-com/johnscottinsurance-com-account-review.png",
      "johnscottinsurance-com/johnscottinsurance-com-business-insurance-michigan.png"
      ]
  },
  "url": "johnscottinsurance.com/",
  "showLink" : true,
  "title": "John Scott Insurance", 
  "tags": ["Agency Site", "Third Party Theme", "WordPress"],
  "description": "John Scott Insurance is one of the few sites we built at Awesurance where we did not use a custom theme. I believe the client liked the look and feel of the Comet theme, which already had the main features they were looking for. With this site I set it up and helped configure all the settings.",
  "order": 89
},
{
  "directory": "neubergerinsurance-com",
  "images": {
    "featuredThumbnail": "neubergerinsurance-com/neubergerinsurance-com_thumb.png",
    "thumbnails": [ 
      "neubergerinsurance-com/neubergerinsurance-com_thumb.png",
      "neubergerinsurance-com/neubergerinsurance-com-business-insurance_thumb.png",
      "neubergerinsurance-com/neubergerinsurance-com-personal-insurance_thumb.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-report-a-claim_thumb.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-flood-insurance_thumb.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-commercial-umbrella-insurance_thumb.png"
      ],
    "cropped": [
      "neubergerinsurance-com/neubergerinsurance-com_cropped-1920x1280.png",
      "neubergerinsurance-com/neubergerinsurance-com-business-insurance_cropped-1920x1280.png",
      "neubergerinsurance-com/neubergerinsurance-com-personal-insurance_cropped-1920x1280.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-report-a-claim_cropped-1920x1280.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-flood-insurance_cropped-1920x1280.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-commercial-umbrella-insurance_cropped-1920x1280.png"
      ],
    "featuredFull": "neubergerinsurance-com/neubergerinsurance-com.png",
    "full": [
      "neubergerinsurance-com/neubergerinsurance-com.png",
      "neubergerinsurance-com/neubergerinsurance-com-business-insurance.png",
      "neubergerinsurance-com/neubergerinsurance-com-personal-insurance.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-report-a-claim.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-flood-insurance.png",
      "neubergerinsurance-com/neubergerinsurance-com-insurance-services-commercial-umbrella-insurance.png"
      ]
  },
  "url": "neubergerinsurance.com/",
  "showLink" : true,
  "title": "Neuberger Insurance", 
  "tags": ["Agency Site", "Third Party Theme", "WordPress"],
  "description": "Neuberger Insurance is one of the few sites we built at Awesurance where we did not use a custom theme. I believe the client liked the look and feel of the Mana theme, which already had the main features they were looking for. With this site, I created a child theme, styled it, and helped configure all the settings.",
  "order": 25
},
{
  "directory": "restaurants-aventadorconnect-com",
  "images": {
    "featuredThumbnail": "restaurants-aventadorconnect-com/restaurants-aventadorconnect-com_thumb.png",
    "thumbnails": [ 
      "restaurants-aventadorconnect-com/restaurants-aventadorconnect-com_thumb.png"
      ],
    "cropped": [
      "restaurants-aventadorconnect-com/restaurants-aventadorconnect-com_cropped-1920x1280.png"
      ],
    "featuredFull": "restaurants-aventadorconnect-com/restaurants-aventadorconnect-com.png",
    "full": [
      "restaurants-aventadorconnect-com/restaurants-aventadorconnect-com.png"
      ]
  },
  "url": "restaurants.aventadorconnect.com/",
  "showLink" : false,
  "title": "Restaurant Owners Insurance", 
  "tags": ["Agency Site", "One-Page Site", "Custom Theme", "WordPress"],
  "description": "Restaurant Owners Insurance was an agency-style site designed to advertise an insurance agency's services in the restaurant insurance niche. Often times, independent insurance agencies tend to specialize in a few particular niches as they are able better learn the needs of particular customers this way, and know what insurance products are available more completely. Like any market, some niches are more or less competitive and more or less profitable. Over the years, I have built dozens of niche insurance sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 22
},
{
  "directory": "pride-joshnadler-com",
  "images": {
    "featuredThumbnail": "pride-joshnadler-com/pride-joshnadler-com_thumb.png",
    "thumbnails": [ 
      "pride-joshnadler-com/pride-joshnadler-com_thumb.png",
      "pride-joshnadler-com/pride-joshnadler-com-about_thumb.png",
      "pride-joshnadler-com/pride-joshnadler-com-contact_thumb.png",
      "pride-joshnadler-com/pride-joshnadler-com-sponsors_thumb.png",
      "pride-joshnadler-com/pride-joshnadler-com-events-list_thumb.png"
      ],
    "cropped": [
      "pride-joshnadler-com/pride-joshnadler-com_cropped-1920x1280.png",
      "pride-joshnadler-com/pride-joshnadler-com-about_cropped-1920x1280.png",
      "pride-joshnadler-com/pride-joshnadler-com-contact_cropped-1920x1280.png",
      "pride-joshnadler-com/pride-joshnadler-com-sponsors_cropped-1920x1280.png",
      "pride-joshnadler-com/pride-joshnadler-com-events-list_cropped-1920x1280.png"
      ],
    "featuredFull": "pride-joshnadler-com/pride-joshnadler-com.png",
    "full": [
      "pride-joshnadler-com/pride-joshnadler-com.png",
      "pride-joshnadler-com/pride-joshnadler-com-about.png",
      "pride-joshnadler-com/pride-joshnadler-com-contact.png",
      "pride-joshnadler-com/pride-joshnadler-com-sponsors.png",
      "pride-joshnadler-com/pride-joshnadler-com-events-list.png"
      ]
  },
  "url": "pride.joshnadler.com/",
  "showLink" : true,
  "title": "Central Oregon Pride", 
  "tags": ["Non-Profit Site", "Custom Theme", "WordPress"],
  "description": "The Central Oregon Pride was a site I built pro bono to help support the 2016 Central Oregon Pride Parade in Bend, OR. This site was fun to work on as I got to get more creative with the theme than the typical business agency-style site. One of the fundraisers they were doing was to sell assorted LGBTQ-related buttons. We scanned those and incorporated them into the background and throughout the custom theme I made.",
  "order": 20
},
{
  "directory": "fallbrook-medmarketing-agency",
  "images": {
    "featuredThumbnail": "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency_thumb.png",
    "thumbnails": [ 
      "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency_thumb.png",
      "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency-mission-statement_thumb.png"
      ],
    "cropped": [
      "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency_cropped-1920x1280.png",
      "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency-mission-statement_cropped-1920x1280.png"
      ],
    "featuredFull": "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency.png",
    "full": [
      "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency.png",
      "fallbrook-medmarketing-agency/fallbrook-medmarketing-agency-mission-statement.png"
      ]
  },
  "url": "fallbrook.medmarketing.agency/",
  "showLink" : false,
  "title": "Fallbrook Boys' & Girls' Club", 
  "tags": ["Non-Profit Site", "Custom Theme", "WordPress"],
  "description": "Fallbrook Boys' & Girls' Club is an agency-style site made for a non-profit. Over the years, I have built dozens of sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated).",
  "order": 33
},
{
  "directory": "unravelpc-wpengine-com",
  "images": {
    "featuredThumbnail": "unravelpc-wpengine-com/unravelpc-wpengine-com_thumb.png",
    "thumbnails": [ 
      "unravelpc-wpengine-com/unravelpc-wpengine-com_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-downloads_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-glitter-shop_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-jlks-story_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-get-involved-kids_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-events-team-glitter_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-what-we-support_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-product-i-sweat-glitter-hoodie_thumb.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-the-people-behind-unravel_thumb.png"
      ],
    "cropped": [
      "unravelpc-wpengine-com/unravelpc-wpengine-com_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-downloads_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-glitter-shop_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-jlks-story_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-get-involved-kids_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-events-team-glitter_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-what-we-support_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-product-i-sweat-glitter-hoodie_cropped-1920x1280.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-the-people-behind-unravel_cropped-1920x1280.png"
      ],
    "featuredFull": "unravelpc-wpengine-com/unravelpc-wpengine-com.png",
    "full": [
      "unravelpc-wpengine-com/unravelpc-wpengine-com.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-downloads.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-glitter-shop.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-jlks-story.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-get-involved-kids.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-events-team-glitter.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-what-we-support.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-product-i-sweat-glitter-hoodie.png",
      "unravelpc-wpengine-com/unravelpc-wpengine-com-about-the-people-behind-unravel.png"
      ]
  },
  "url": "unravelpc.wpengine.com/",
  "showLink" : false,
  "title": "Unravel Pediatric Cancer", 
  "tags": ["Non-Profit Site", "WooCommerce", "Custom Theme", "WordPress"],
  "description": "Unravel Pediatric Cancer is an agency-style site made for a non-profit. Over the years, I have built dozens of sites similar to this one. As with most of the Awesurance workflow, I would set up the site, customized one of my own in-house themes, set up the styles, install necessary plugins, and add any additional functionality. My parter Josh Nadler would typically be in charge of logo / graphic design and general design decisions. Then we would possibly have a third person inputting text and image content into the pages, depending upon how much content needed to be added (and whether or not it could be automated). This site includes an e-commerce store built with WooCommerce.",
  "order": 28
},
]

export default tileData;

