import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import NavMenu from './NavMenu';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  menuBtn: {
    color: '#FFF',
  },
});

export default function MenuDrawer() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, 'left': open });
  };



  return (
    <div className="mobile-menu-toggle">
      <Button className={classes.menuBtn} onClick={toggleDrawer(true)}><MenuIcon />&nbsp;Menu</Button>
        <Drawer open={state.left} onClose={toggleDrawer(false)}>

          <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <NavMenu drawer className="nav-menu--drawer" />
          </div>
        </Drawer>
      </div>
    );
  }

