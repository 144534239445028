import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RonHoltMarker from './img/ronholt_marker.svg';


const useStyles = makeStyles({
  root: {
    width: 'max-content',
    height: 'max-content',
    borderRadius: '10px',
    backgroundColor: '#FFF',
    overflow: 'hidden',
    textAlign: 'center',
  },
  myNameIs: {
    color: '#FFF',
    backgroundColor: '#F00',
    padding: '0.5rem',
    fontFamily: '"Raleway", sans-serif',
    fontSize: '1.5rem',
    display: 'block',
  },
  myName: {
    margin: '2rem 0.5rem',
  },
});

export default function Nametag(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.myNameIs}>Hi there, my name is</span>
      <img className={classes.myName} src={RonHoltMarker} alt="Ron Holt" />
    </div>
  );
}
