import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
  },
  tileInfoBar: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: '#FFFFFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tileTitleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: '0.5em',
    '& > *': {
    }
  },
  tileTitle: {
    fontSize: '1.2em',
  },
  tileTags: {
  },
  externalLink: {
    margin: '1em',
  },
  infoBtn: {
    cursor: 'zoom-in',
  },
  infoIcon: {
    color: '#FFF',
    '&:hover' : {
      transform: 'scale(1.1)',
      opacity: '0.8',
    }
  },
}));



export default function PortfolioTileInfo(props) {

  const classes = useStyles();


  const tileTags = props.tileData.tags;
  const tileTagsShort = (tileTags.length > 3) ? 
                          props.tileData.tags.slice(0, 3).join(', ') + '...'
                          : tileTags.join(', ');
  

  return (
    <div className={classes.root}>
      <div className={classes.tileInfoBar}> 
        <div className={classes.tileTitleContainer}>
          <span className={classes.tileTitle}>
            {props.tileData.title}
          </span>
          <span className={classes.tileTags}>
            tags:&nbsp;{tileTagsShort}
          </span>
        </div>
        <IconButton 
          color="primary" 
          aria-label={props.tileData.title + ' more info'} 
          className={classes.infoBtn}
        >
          <InfoIcon className={classes.infoIcon} fontSize="large" />
        </IconButton>
      </div>
    </div>
  
  );


}

