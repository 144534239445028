const resumeData = {
    "basics": {
          "name": "Ron Holt",
          "label": "Web Developer",
          "summary": "Web Developer with 5+ years experience in search of full-time work to level-up skills and make a positive impact on the right team.",
          "tldr": [
              "Extensive experience building custom WordPress themes for dozens of client websites.",
              "1+ year working full-time in Linux and improving back-end skills",
              "New to React.js and loving it!"
          ],
          "topSkills": ["HTML", "CSS/SASS", "JavaScript/React.js", "Linux", "MySQL", "PHP", "WordPress"],
          "location": {
                  "address": "",
                  "postalCode": "",
                  "city": "San Francisco Bay Area",
                  "countryCode": "US",
                  "region": "California"
                },
          "profiles": [
                  {
                            "network": "Github",
                            "username": "GitarMan",
                            "url": "https://github.com/GitarMan"
                          },
                  {
                            "network": "LinkedIn",
                            "username": "ronholt",
                            "url": "https://www.linkedin.com/in/ronholt/"
                          }
                ]
        },
    "work": [
          {
                  "name": "Awesurance",
                  "location": "Bend, OR",
                  "description": "Awesurance is a full-service web development, marketing, & design agency that specializes in helping independent insurance agencies",
                  "position": "Partner & Web Developer",
                  "url": "http://awesurance.com",
                  "startDate": "July 2014",
                  "endDate": "April 2020",
                  "summary": [
                      "At Awesurance I built websites, implemented / administered software solutions, and did full-service online marketing primarily for independent insurance agencies located all across the United States. I was promoted to Full-Partner after one year.",
                      "We worked extensively with WordPress as a content management system and a development platform. I personally developed dozens of custom WordPress themes for client sites across a wide range of functional and design requirements.",
                  ],
                  "technologies": [
                          "HTML",
                          "CSS / SASS",
                          "JavaScript / Node.js / jQuery / React",
                          "Linux / Apache / MySQL / PHP",
                          "WordPress Themes & Plugins",
                          "Photoshop / GIMP",
                          "Illustrator / Inkscape",
                          "DNS / Hosting (WP Engine; Cloudflare; GoDaddy; BlueHost; HostGator; Amazon Web Services S3) ",
                          "Email: Administration / Marketing / Transactional (Amazon Web Services SES; Mailgun; Mailchimp / Mandrill; Constant Contact; Infusionsoft) ",
                          "Web Scraping (Scrapy / Python)",
                          "Embedded Video (Wistia; YouTube; Vimeo)",
                          "Podcast Audio & Publishing (Audacity; Apple Podcasts; Stitcher; Google Play)",
                          "E-commerce (WooCommerce)",
                          "Google Analytics / Search Engine Optimization",
                          "Display Advertising / Pay Per Click Advertising (Google; Facebook; LinkedIn)",
                  ],
                  "highlights": []
                },

          {
                  "name": "North Bay Brewery Tours",
                  "location": "Sonoma County, CA",
                  "description": "",
                  "position": "Co-Founder & Owner",
                  "url": "http://northbaybrewerytours.com",
                  "startDate": "November 2010",
                  "endDate": "February 2020",
                  "summary": [
                    "North Bay Brewery Tours is a tour bus company that specializes in all-inclusive guided tours of local craft Breweries, Wineries, Cideries, & Distilleries.",
                    "Five years ago, I stepped away from managing North Bay Brewery Tours to pursue my career in Web Development."
                  ],
                  "highlights": null 
                },

          {
                  "name": "Thalassic Media LLC",
                  "location": "Pasadena, CA",
                  "description": "Thalassic Media was a Web Design / Graphic Design company.",
                  "position": "Part-Time Sales Manager",
                  "url": "",
                  "startDate": "January 2010",
                  "endDate": "December 2010",
                  "summary": [],
                  "highlights": [
                            "Explored marketing opportunities for a professional web designer",
                            "Developed a lead generation process from scratch",
                            "Managed all non-technical project details including client interaction, hiring subcontractors, and making sure deadlines were met"
                          ]
                },

          {
                  "name": "OhCal Foods LLC / SUBWAY",
                  "location": "Los Angeles County, CA",
                  "description": "OhCal Foods is the Development Agent for SUBWAY restaurants in Los Angeles County",
                  "position": "Senior Business Consultant",
                  "url": "",
                  "startDate": "April 2008",
                  "endDate": "November 2010",
                  "summary": [''],
                  "highlights": [
                            "Facilitated the opening of 11 different restaurants and helped franchisees manage growth",
                            "In one year, promoted to ‘Senior Business Consultant’ and managed a team of five consultants",
                            "Ensured that my team evaluated a set of ~170 restaurants each month",
                            "Implemented new technology solutions, including a bar code check-in system for events and GPS tracking & time-clock for field employees",
                          ]
                },

        ],
    "volunteer": [
          {
                  "organization": "",
                  "position": "",
                  "url": "",
                  "startDate": "",
                  "endDate": "",
                  "summary": "",
                  "highlights": [
                            ""
                          ]
                }
        ],
    "education": [
          {
                  "institution": "Sonoma State University",
                  "area": "Business Administration - Entrepreneurship / Small Business Management",
                  "studyType": "Bachelor of Science",
                  "startDate": "2003",
                  "endDate": "2007",
                  "gpa": "",
                  "courses": [
                            "",
                            ""
                          ]
                },
          {
                  "institution": "freeCodeCamp",
                  "area": "Software Engineering / Web Development",
                  "studyType": `freeCodeCamp Developer Certifications:
• Responsive Web Design
• JavaScript Algorithms and Data Structures
• Front End Libraries`,
                  "startDate": "2016",
                  "endDate": "2019",
                  "gpa": "",
                  "courses": [
                            "",
                            ""
                          ]
                },
        ],
    "awards": [
          {
                  "title": "",
                  "date": "",
                  "awarder": "",
                  "summary": ""
                }
        ],
    "publications": [
          {
                  "name": "",
                  "publisher": "",
                  "releaseDate": "",
                  "url": "",
                  "summary": ""
                }
        ],
    "skills": [
          {
                  "name": "",
                  "level": "",
                  "keywords": [
                            "",
                            "",
                            ""
                          ]
                },
          {
                  "name": "",
                  "level": "",
                  "keywords": [
                            "",
                            "",
                            ""
                          ]
                }
        ],
    "languages": [
          {
                  "language": "English",
                  "fluency": "Native speaker"
                }
        ],
    "interests": [
          {
                  "name": "",
                  "keywords": [
                            "",
                            ""
                          ]
                }
        ],
    "references": [
          {
                  "name": "",
                  "reference": ""
                }
        ],
    "projects": [
          {
                  "name": "",
                  "description": "",
                  "highlights": [ 
                            "",
                            "",
                            ""
                          ],
                  "keywords": [
                            "", "", ""
                          ],
                  "startDate": "",
                  "endDate": "", 
                  "url": "",
                  "roles": [ 
                            "", ""
                          ],
                  "entity": "",
                  "type": ""
                }
        ],
    "meta": {
          "canonical": "https://raw.githubusercontent.com/jsonresume/resume-schema/master/resume.json",
          "version": "v1.0.0",
          "lastModified": "2017-12-24T15:53:00"
        }
}

export default resumeData;
