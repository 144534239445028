import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import skillData from './skillData';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '10rem',
  },
  skillChartSkill: {
    padding: '1rem',
    '&:hover': {
      background: 'rgba(255,255,255,0.5)',
      cursor: 'context-menu',
    },
  },
  skillChartSkill__header: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  skillChartSkill__title: {
    paddingRight: '1rem',
  },
  skillChartSkill__subtitle: {

  },
  progressBar: {
    height: '25px',
  },
  tooltipCustom: {
    margin: '1rem',
    maxWidth: '1000px',
  }
}));

export default function SkillsChart() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {skillData.map( (skill, i) => (
        <Tooltip 
          key={'skillChartSkill-'+i}
          title={
            <Typography variant='h5'>
              {skill.details}
            </Typography>
          } 
          classes={{tooltip: classes.tooltipCustom}}
          arrow
        >
          <div 
            className={classes.skillChartSkill}
          >
            <div className={classes.skillChartSkill__header}>
              <Typography 
                className={classes.skillChartSkill__title} 
                variant='h4'
              >
                {skill.skill}
              </Typography>
              <Typography 
                className={classes.skillChartSkill__subtitle} 
                variant='h5'
              >
                {skill.subtitle}
              </Typography>
            </div>
            <LinearProgress 
              className={classes.progressBar} 
              variant="determinate" 
              value={skill.proficiency} 
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
}
