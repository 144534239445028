import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import SendIcon from '@material-ui/icons/Send';
import useForm from "./ContactFormUseForm";
import validate from './ContactFormValidationRules';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    padding: '1rem 5%',
    textAlign: 'right',
    marginBottom: '5rem',
    '& .MuiTextField-root': {
      margin: '0.5rem 0',
      width: '100%',
    },
  },
  button: {
  }
});


export default function ContactForm() {
  const classes = useStyles();

	const [feedbackText,  setFeedbackText]  = React.useState('');
  const [feedbackOpen,  setFeedbackOpen]  = React.useState(false);
  const [feedbackError, setFeedbackError] = React.useState(false);

	const handleFeedbackClose = (event, reason) => {
    setFeedbackOpen(false);
		if (!feedbackError) { resetForm(); }
    setFeedbackError(false);
	}

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
		resetForm
  } = useForm(contactSubmit, validate);

  function contactSubmit() {
    fetch('https://ronholt.info:3002/send-mail', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then( response => {
      if ( response.ok ) {
        setFeedbackError(false);
        return response.text();
      } else {
        setFeedbackError(true);
        return response.json()
          .then( res => {
            let errMsg = 'Error: ';
            res.errors.forEach( obj => { errMsg += obj.msg+'; '; } );
            return errMsg;
          });
      }
    })
    .then( text => {
      console.log(text);
      setFeedbackText(
        <Typography align="center" variant="body1">
          { text }
          <br />
          <br />
          {"You can also reach me on LinkedIn:"}
          <br />
          <Link 
            href="https://www.linkedin.com/in/ronholt/" 
            target="_blank" 
            rel="noopener"
            color="error"
            align="center"
          >
            {'linkedin.com/in/ronholt/'}
          </Link>
        </Typography>
      );
      setFeedbackOpen(true);
    })
    .catch( error => {
      setFeedbackError(true);
      setFeedbackText(
        <Typography variant="body1" align="center">
          {"I'm sorry, there was a problem submitting your message. If you need to get in touch with me, you can also reach me on LinkedIn: "}
          <Link 
            href="https://www.linkedin.com/in/ronholt/" 
            target="_blank" 
            rel="noopener"
            color="error"
          >
          {'linkedin.com/in/ronholt/'}
          </Link>
        </Typography>
      );
      setFeedbackOpen(true);
    });
  }

  return(
		<form 
			id="contactForm"
			onSubmit={handleSubmit}
			className={classes.root} 
			noValidate 
			autoComplete="off"
		>
			<TextField 
				name="name" 
				id="contact__name" 
				label="Name" 
				placeholder="First 
				Last" 
				variant="outlined" 
				onChange={handleChange}
				value={values.name || ''}
				required 
				{...( errors.name && {'error':true} )}
				helperText={errors.name}
			/>
			<TextField 
				name="email" 
				id="contact__email" 
				label="Email" 
				placeholder="you@yourdomain.com" 
				variant="outlined" 
				onChange={handleChange}
				value={values.email || ''}
				required 
				{...( errors.email && {'error':true} )}
				helperText={errors.email}
			/>
			<TextField 
				name="phone" 
				id="contact__phone" 
				label="Phone" 
				placeholder="555-555-5555" 
				variant="outlined" 
				onChange={handleChange}
				value={values.phone || ''}
			/>
			<TextField
				name="message"
				id="contact__message"
				label="Message"
				placeholder="How can I help you?"
				multiline
				variant="outlined"
				onChange={handleChange}
				value={values.message || ''}
			/>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				className={classes.button}
				size="large"
				endIcon={<SendIcon />}
			>
				Send
			</Button>
			<Snackbar 
				open={feedbackOpen} 
				onClose={handleFeedbackClose}
				message={feedbackText}
        action={
          <React.Fragment>
            <IconButton 
							size="small" 
							aria-label="close" 
							color="inherit" 
							onClick={handleFeedbackClose}
						>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
			/>
		</form>
  );
}
