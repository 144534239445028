import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import PortfolioTileInfo from './PortfolioTileInfo';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwsScaleOutAnimation from 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import './PortfolioTile.css';


const useStyles = makeStyles(theme => ({
  gridThumbnail: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '15rem',
    borderTop:    '0.5rem solid transparent',
    borderRight:  '0.25rem solid transparent',
    borderBottom: '0',
    borderLeft:   '0.25rem solid transparent',
    cursor: 'zoom-in',
    '& > div:hover': {
      boxShadow: '2px 2px 2px rgba(63, 81, 181, 0.8)',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  externalLink: {
    margin: '1em',
  },
  modalCard: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: '#FFF',
    minWidth: '300px',
    width: 'calc(100vw - 8rem)',
    maxHeight: 'calc(100vh - 4.5rem)',
    margin: '4rem 4rem 0.5rem',
    overflowY: 'scroll',
  },
  closeBtn: {
    color: 'rgba(255,255,255,0.8)',
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'fixed',
    top: '5rem',
    right: '6rem',
    zIndex: '99',
    '&:hover' : {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  modalTopBtn: {
    color: 'rgba(255,255,255,0.8)',
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'fixed',
    top: '5rem',
    right: '10rem',
    zIndex: '99',
    '&:hover' : {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modalHeader: {
    textAlign: 'center',
    padding: '1rem 1rem 4rem',
    '& > h2': {
      margin: '0',
    },
  },
}));


export default function PortfolioTile(props) {

  const classes = useStyles();

  /*Open and Close Modal*/
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  /*Slider Logic*/
  const [sliderImgObjFit, setSliderImgObjFit] = useState('cover');
  const [sliderImgHeight, setSliderImgHeight] = useState('1000px');
  const variableStyles = makeStyles( () => ({
    tileSlider: {
      '& img': {
        objectFit: sliderImgObjFit,
      },
      '& > div > div.awssld__container': {
          paddingBottom: sliderImgHeight,
      },
    }
  }));

  const varClasses = variableStyles();

  const delayAdjustSliderHeight = (slider) => {
    setTimeout( () => { adjustSliderHeight(slider) }, 3000);
  }; 

  const adjustSliderHeight = (slider) => {

    const imgWidth = slider.currentSlide.firstChild.firstChild.naturalWidth;
    const imgHeight = slider.currentSlide.firstChild.firstChild.naturalHeight;
    const sliderWidth = slider.element.offsetWidth;
    const sliderHeight = sliderWidth * imgHeight / imgWidth;

    setSliderImgObjFit('contain');
    setSliderImgHeight(sliderHeight+'px'); 
  };


  const tileDescription = props.tileData.description;
  const tileTags = props.tileData.tags;

  const id = props.tileData.directory;
  const modalID = 'tile-modal-'+id; 

  const modalScrollToTop = () => document.getElementById(modalID).scrollTo(0,0);
    
  const fullsizeArr = props.tileData.images.full;


  return (
      <Grid 
        item xs={12} sm={6} md={4}
        className={classes.gridThumbnail}
        style={{backgroundImage: 'url("img/'+props.tileData.images.featuredThumbnail+'")'}}
        data-aos="flip-up"
        data-aos-duration="500"
        onClick={handleOpen}
      >
        <PortfolioTileInfo tileData={props.tileData}  />
      
        <Modal
          className={classes.modal}
          aria-labelledby={'tile-title__'+props.tileData.directory}
//          aria-describedby={'tile-description__'+props.tileData.directory}
          open={open}
          onClose={handleClose}
        >

          <Card id={modalID} className={classes.modalCard}>
            <IconButton onClick={modalScrollToTop} className={classes.modalTopBtn} aria-label="return to tile top">
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton onClick={handleClose} className={classes.closeBtn} aria-label="close tile">
              <CloseIcon />
            </IconButton>
            <header className={classes.modalHeader}>
              <Typography id={'tile-title__'+props.tileData.directory} align="center" variant="h1">
                {props.tileData.title}
              </Typography>
              <Typography variant="body1" align="center" id={'tile-description-'+id}>{tileDescription}</Typography>
              <br />
              <Typography variant="body1" align="center" id={'tile-tags-'+id}>tags: {tileTags.join(', ')}</Typography>
              { 
                props.tileData.showLink ?
                  <Button className={classes.externalLink} variant="contained" startIcon={<LinkIcon />} href={'http://'+props.tileData.url} target="_blank" rel="noopener">Visit: {props.tileData.title}</Button>
                : ''
              }
            </header>
            <AwesomeSlider
              className={varClasses.tileSlider}
              cssModule={AwsScaleOutAnimation}
              animation="scaleOutAnimation"
              onFirstMount={delayAdjustSliderHeight}
              onTransitionEnd={adjustSliderHeight}
              onTransitionStart={modalScrollToTop}
            >
              {fullsizeArr.map( (img, i) => <div key={'fullSizeImg-'+i} data-src={'img/'+img} />)}
            </AwesomeSlider>
          </Card>
    
        </Modal>
      </Grid>
  );
}
