import React from 'react';
import './SectionHeader.css';
import Typography from '@material-ui/core/Typography';

export default function SectionHeader(props) {

  return (
    <header className="section-header">
      <Typography variant="h2" align="center">{props.text}</Typography>
    </header>
  );
}
